import React from "react";
import { Link } from "react-router-dom";
import { validateRoute } from "./helpers/auth";

export function AppHome() {

    const auth = JSON.parse(localStorage.getItem('auth'))

    const content = () => {
        return (
            <div className="tiles__wrapper">
                <div className="tiles__wrapper">
                    {validateRoute('/scan') === true &&
                        <div className="tiles__column">
                            <Link to={"/scan"} className={"tile tile__scannen"}>
                                <span className="icon"></span>
                                <span className="tile__title">
                                    Scan
                                </span>
                            </Link>
                        </div>
                    }
                    {validateRoute('/transport') === true &&
                        <div className="tiles__column">
                            <Link to={"/transport"} className={"tile tile__transport"}>
                                <span className="icon"></span>
                                <span className="tile__title">
                                    Transport
                                </span>
                            </Link>
                        </div>
                    }
                    {validateRoute('/addossiers') === true &&
                        <div className="tiles__column">
                            <Link to={"/addossiers"} className={"tile tile__verhuur"}>
                                <span className="icon"></span>
                                <span className="tile__title">
                                    Ad dossiers
                                </span>
                            </Link>
                        </div>
                    }
                    {validateRoute('/products') === true &&
                        <div className="tiles__column">
                            <Link to={"/products"} className={"tile tile__depotopslag"}>
                                <span className="icon"></span>
                                <span className="tile__title">
                                    Artikelbeheer
                                </span>
                            </Link>
                        </div>
                    }
                    {validateRoute('/inspections') === true &&
                        <div className="tiles__column">
                            <Link to={"/inspections"} className={"tile tile__inspections"}>
                                <span className="icon"></span>
                                <span className="tile__title">
                                    Keuringen
                                </span>
                            </Link>
                        </div>
                    }
                </div>
            </div>
        )
    }

    const title = () => {
        return (
            <h3>Welkom <strong>{auth.fullName}</strong></h3>
        )
    }

    return (
        <div className="main">
            <div className="main__right">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-8 col-lg-6">
                        <div className="component" style={{ maxWidth: "600px" }}>

                            <div className="tiles-home-title">
                                {title()}
                            </div>

                            {content()}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

