import React from "react";
import { Link } from 'react-router-dom';
import { resolvePath } from "../../app/helpers/global";

export function PageTabs({ parents, tabGroup, variables }) {

    const routesTree = JSON.parse(localStorage.getItem("routes-tree"));

    let tabList = [];
    if (tabGroup !== undefined && tabGroup !== null) {
        Object.values(routesTree).forEach((entry) => {
            if (tabGroup === entry.tabGroup) {
                tabList.push({ path: resolvePath(entry.fullPath, variables), title: entry.pageTitle });
            }
        });
    }

    const tabEntry = (tab) => {
        let match = (tab.path === window.location.pathname);
        return (
            <Link
                className={"tab" + (match ? " tab--active" : "")}
                to={tab.path}
            >
                {tab.title} 
            </Link>
        );
    }

    return (
        <>
            {tabList !== undefined && Object.keys(tabList).length > 0 &&
                <div className="components tabs">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <div className="component">
                                    <div className="tab-bar">

                                        {Object.values(tabList).map((tab, i) => (
                                            <div key={'tab-index-' + i}>
                                                 {tabEntry(tab)}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}