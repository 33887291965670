import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ValidateResponse, getCurrentPage, setCurrentPage } from "../helpers/global";
import { fetchTransports } from "../helpers/transport";
import { List } from "../../components/List";
import { ListPagination } from "../../components/List/Pagination";
import { Message } from "../../components/Message";

export function AppTransportOverview({ current, setFilterFields, filterValues, identifier}) {

    let pageSize = 10; // default values ergens globaal opslaan?
    const [data, setData] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [pageNr, setPageNr] = useState(getCurrentPage(current));
    const [totalCount, setTotalCount] = useState(0);
    const [error, setError] = useState();
    const [filterString, setFilterString] = useState("{}");
    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem(identifier)));
    const navigate = useNavigate();

    if (JSON.stringify(filterValues) !== filterString) {
        setFilterString(JSON.stringify(filterValues));
        setIsLoaded(false);
        setFilters(filterValues);
        setCurrentPage(1, current, setPageNr);
    }

    let storedPageNr = getCurrentPage(current);
    if (pageNr !== storedPageNr) {
        setPageNr(storedPageNr);
    }

    const handlePagination = (page) => {
        setCurrentPage(page, current, setPageNr);
    }

    let listColumns = [
        { key: "transportName", type: "link", path: "/transport/" + current + "/details/:transportId", id: "transportId", hideView: "large" },
        { key: "transportName", label: "Transport", type: "text", hideView: 'small' },
        { key: "pickupWarehouses", label: "Inladen", type: "text" },
        { key: "dropoffWarehouses", label: "Uitladen", type: "text" },
        { key: "origin", label: "Oorsprong", type: "text" },
        { key: "driverUser", label: "Chauffeur", type: "text" },
        { key: "transportId", label: "", type: "link", path: "/transport/" + current + "/details/:transportId", id: "transportId", hideView: "small" }
    ];

    useEffect(() => {

        // The filters are set here and send to parent onloaded
        const defineFilters = () => {

            let originOptions = [
                { value: "SALE", label: "Verkoop"},
                { value: "RENTAL", label: "Verhuurdossier"},
                { value: "DEPOT", label: "Depot"},
                // CLEANING:"Reiniging", // moet die erbij?
                { value: "REPAIR", label: "Reparatie"},
                { value: "WAREHOUSE", label: "Magazijnverplaatsing (intern)"}
            ]
        
            let filterFields = {
                'transportId' : {type:'text', 'label':"Route id"} ,
                'street' : {type:'text', 'label':"Adres"} ,
                'city' : {type:'text', 'label':"Plaats"} ,
                'customer' : {type:'text', 'label':"Naam klant"} ,
                'debtorNumber' : {type:'text', 'label':"Debiteurnummer"} ,
                'dateFrom' : {type:'date', 'label':"Datum vanaf", options: {min:"-10", max:"+1"}} ,
                'dateTo' : {type:'date', 'label':"Datum tot", options: {min:"-10", max:"+1"}} ,
                'origin' : {type:'select', 'label':"Oorsprong", options:originOptions} , // HIER BEN IK: oorsprong ophalen (in transport helpers?)
                'driver' : {type:'text', 'label':"Chauffeur"} ,
            }
            setFilterFields(filterFields); 
        }

        const callData = {
            filters: {
                context: current,
                // sort: sort,
                limitOffset: pageSize * pageNr - (pageSize - 1),
                limitRows: pageSize,
                ...filters
            }
        }
        async function fetchData() {
            await fetchTransports(callData).then((res) => {

                let response = ValidateResponse(res.data, 'transports');

                if (response.status === 'error') {
                    setError(response.message);
                }
                else {
                    setData(response.transports ?? null);
                    setTotalCount(res.data.totalCount ?? 0);
                    defineFilters();
                }
                setIsLoaded(true);
                

            }).catch((error) => {
                const response = ValidateResponse(error, 'status');
                console.log(error);
                setError(response.message);
            })
        }
        fetchData();
    }, [filters, pageNr, setTotalCount, setIsLoaded, setError, setData, navigate, isLoaded, current, pageSize, setFilterFields])

    if (error !== undefined) {
        return (<Message type="error" message={error} />);
    }
    if (isLoaded === false) {
        return (<Message type="loading" />);
    }
    if (data === undefined || data === null || Object.keys(data).length === 0) {
        return (<Message type="noData" />);
    }

    return (
        <>
            <List
                columns={listColumns}
                data={data}
                identifier="tranport"
            />

            <div className={'spacer'}></div>

            <ListPagination
                className="pagination-bar"
                currentPage={pageNr}
                totalCount={totalCount}
                pageSize={pageSize}
                setCurrentPage={handlePagination}
            />
            <div style={{ height: '30px' }}></div>
        </>
    );
}