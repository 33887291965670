import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ValidateResponse } from "../../helpers/global";
import { getRentableSerialnumberList, fetchWarehouses } from "../../helpers/addossiers";
//import { useForm } from "react-hook-form";
import { List } from "../../../components/List";
import { FormFields } from "../../../components/Form/Fields";
//import { FormInputSubmit } from "../../../components/Form/Input/Submit";
import { returnValues, removeValue, validateData } from "../../../components/Form/Formfunctions";
import { ContentWrapper } from "../../../components/Content/Wrapper";
import { Message } from "../../../components/Message";
import { AppAddossiersSelectSerialnumber } from "../Select/Serialnumber"


export function AppAddossiersSelectChangedossiers({ userId, processSelect, setGlobalMessage }) {

    const navigate = useNavigate();
    // const [customer, setCustomer] = useState();
    // const { handleSubmit } = useForm();
    const [changeList, setChangeList] = useState([]); // the productIds to change
    const [dossiers, setDossiers] = useState([]); // product ids of all currenly rented products
    const [warehouses, setWarehouses] = useState([]);
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState();
    //const [error, setError] = useState(); // wat is het verschil tussen setError en seterrors???
    const [isSubmitted, setSubmitted] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [row, setRow] = useState(null);


    let formFields = {}
    let marker = '*';
    if (warehouses.length > 0) {
        formFields.warehouse = {
            type: "select",
            label: "Magazijn inname" + marker,
            options: warehouses,
            value: values['warehouse'],
            rules: ["required"]
        }
    }

    const setFormattedList = (listDossiers) => {
        let formatted = [];
        listDossiers.forEach((dossier) => {
            dossier.pickup = dossier.name + " | " + dossier.serialNumber;
            if (dossier.deliverName !== undefined) {
                dossier.dropoff = dossier.deliverName + " | " + dossier.deliverSerialNumber;
                //formatted.unshift(dossier);
             }
            // else {
                formatted.push(dossier);
            //}
        });

        setDossiers(formatted);
        return formatted;
    }

    const handleChecklist = (name, value) => {

        let newValues = values;

        if (name.indexOf('productId__') !== -1) {

            let cases = dossiers;
            let selectedCases = changeList;
            let checkedCase = name.replace('productId__', '');

            if (value === true && selectedCases.includes(checkedCase) === false) {
                selectedCases.push(checkedCase);
            }
            else if (value === false && selectedCases.includes(checkedCase) === true) {
                var index = selectedCases.indexOf(checkedCase);
                if (index !== -1) {
                    selectedCases.splice(index, 1);
                }
            }

            cases.forEach((rental, i) => {
                if (rental.rentalCaseId === checkedCase) {
                    cases[i].isChecked = value;
                    cases[i].iconChecked = value === true ? 'completed' : null;
                }
            });

            setFormattedList(cases);
            setChangeList(selectedCases);
            newValues.selectedCases = selectedCases;
        }
        else {
            newValues[name] = value;
        }

        setValues(newValues);
        setRefresh(refresh === false); // @todo uitzoeken waarom pagina niet refreshed op setValues ..
        return;
    }

    useEffect(() => {
        fetchWarehouses().then((res) => {
            const response = ValidateResponse(res.data, 'warehouses');
            if (response.status === 'error') {
                setGlobalMessage(response.message, 'error');
            } else {
                setWarehouses(response.warehouses);
            }
        }).catch((error) => {
            let response = ValidateResponse(error);
            setGlobalMessage(response.message, 'error');
        })
    }, [setWarehouses, navigate])

    useEffect(() => {
        let processType = 'RETURN';

        let callData = {
            'userId': userId,
            'type': processType
        }

        if (dossiers === undefined || dossiers.length === 0) {
            getRentableSerialnumberList(callData).then((res) => {
                const response = ValidateResponse(res.data, 'dossiers');
                if (res.data != null) {
                    response.dossiers.forEach((dossier) => {
                        dossier.icon = 'add';
                    });
                    setFormattedList(response.dossiers);
                }
            }).catch((error) => {
                let response = ValidateResponse(error);
                setGlobalMessage(response.message, 'error');
            })
        }

    }, [setFormattedList, navigate, userId, dossiers])

    // if (error !== undefined) {
    //     return (<Message type="error" message={error} />);
    // }

    if (dossiers.lenght === 0) {
        return (<Message type="noData" />);
    }

    const handleSelect = () => {

        setSubmitted(true);
        let validation = validateData(formFields, values, true);
        let data = values;
        if (validation.status !== 'success') {
            setErrors(validation.errors);
            let globalMessage = validation.errors.globalMessage ?? 'Het formulier kan niet verzonden worden';
            setGlobalMessage(globalMessage, 'error');
            return;
        }

        let formData = {
            'userId': userId,
            'products': changeList,
            'warehouseId': data.warehouse
        }

        processSelect(formData);
    }

    const processSelectedDelivery = (deliverd) => {

        changeList.forEach((item) => {
            if (item.deliverProductId === deliverd.productId) {
                setGlobalMessage('Serienummer staat reeds in de lijst', 'error');
                return;
            }
        });

        let newDossiers = dossiers;
        let dossierToAdd = null;

        newDossiers.forEach((dossier) => {

            if (dossier.productId === row.productId) {
                dossier.deliverProductId = deliverd.productId;
                dossier.deliverName = deliverd.name;
                dossier.deliverSerialNumber = deliverd.serialNumber;
                dossierToAdd = dossier;
                dossier.icon = 'completed';
                dossier.iconRemove = 'remove';
            }
        })

        setFormattedList(newDossiers)

        if (dossierToAdd !== null) {
            setChangeList([...changeList, dossierToAdd])
        }

        setRow(null);
    }

    const handleClick = (row) => {

        setGlobalMessage(null);

        dossiers.forEach((dossier) => {
            if (dossier.productId === row.productId && dossier.deliverName === undefined) {
                setRow(row);
            }
        })
    };

    const handleRemove= (row) => {

        setGlobalMessage(null);

        dossiers.forEach((dossier, index) => {

            if (dossier.productId === row.productId) {

                // @eric: ik snap dit niet ... hoezo indien not undefined?. Anyway dit werkt niet, row wordt niet uit changeList gehaald
                // if (dossier.deliverName !== undefined) { // ??????

                // Reset serialnumber on active cases
                dossier.deliverName = undefined;
                dossier.dropoff = undefined;
                dossier.deliverProductId = undefined;
                dossier.deliverSerialNumber = undefined;
                dossier.icon = 'add';
                dossier.iconRemove = null;

                // Remove from list
                let newChangeList = changeList;
                let newOverview = dossiers;
                newOverview[index] = dossier;
       
                newChangeList.forEach((dossier, index) => {
                    if (dossier.productId === row.productId) {
                        newChangeList.splice(index);
                    }
                });

                setChangeList(newChangeList);
                setDossiers(newOverview);
                setRefresh(refresh === false);
            }
        })
    };

    let columnsReturn = [
        { key: "productId", type: "button", function: handleClick, variableIcon: 'icon', className:'col-1' },
        { key: "pickup", label: "Ophalen", type: "text" },
        { key: "dropoff", label: "Afleveren", type: "text" },
        // { key: "name", label: "Naam ophalen", type: "text" },
        // { key: "serialNumber", label: "Serienummer ophalen", type: "text" },
        // { key: "deliverName", label: "Naam afleveren", type: "text" },
        // { key: "deliverSerialNumber", label: "Serienummer afleveren", type: "text" },
        { key: "isChecked", type: "button", function: handleRemove, variableIcon:'iconRemove', className:'col-1'}
    ];

    const setInputValues = (key, value, identifier = null) => {

        // reset errors
        if (isSubmitted === true) {
            setSubmitted(false);
        }
        if (errors) {
            setErrors(removeValue(errors, key, identifier));
        }

        setValues(returnValues(values, key, value, identifier));
    }

    const setInputErrors = (key, value, identifier = null) => {

        // add or remove error
        let newErrors = errors;
        if (value !== undefined && value !== null) {
            newErrors = returnValues(errors, key, value, identifier);
        }
        else {
            newErrors = removeValue(errors, key, identifier);
        }
        setErrors(newErrors);
    }

    const contentForm = () => {
        return (
            <FormFields
                formFields={formFields}
                setValues={setInputValues}
                setErrors={setInputErrors}
                formValues={values}
                formErrors={errors}
                //identifier = null
                isSubmitted={isSubmitted}
            />
        )
    }

    const cancelRow = () => {
        setRow(null);
    }

    return (<div>

        {row !== null &&
            <AppAddossiersSelectSerialnumber
                singleMode={true}
                userId={userId}
                productId={row !== null ? row.productId : null}
                setGlobalMessage={setGlobalMessage}
                processSelect={processSelectedDelivery}
                cancel={cancelRow}
                title={"Ad uitleen wisselen: " + row.name + " | " +  row.serialNumber}
            />
        }
        {row == null &&
            <>
                <ContentWrapper type="title" content="Ad uitleen hulpmiddel wisselen" />
                <ContentWrapper type='outline-box' content={contentForm()} />

                {changeList.length > 0 &&
                    <ContentWrapper
                        type="title-button"
                        content="Aangevinkte hulpmiddelen zijn gewisseld"
                        handleFunction={handleSelect}
                        params={{ label: "Bevestigen" }}
                    />
                }

                {/** @eric: dossiers is geen object.. dus waarom Object.keys gebruiken?  */}
                {Object.keys(dossiers).length > 0 &&
                    <List
                        columns={columnsReturn}
                        data={dossiers}
                        identifier="return"
                        handleAction={handleChecklist}
                    />
                }
            </>
        }
    </div>
    );
}