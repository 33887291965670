import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { AppInspectionsActions } from "../../app/Inspections/Actions";
import { PageHeader } from "../../components/Page/Header";
import { PageTopbar } from "../../components/Page/Topbar";
//import { getPageTitle } from "../../app/helpers/global";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function RoutesInspectionsActions({ parents, current, title }) {
    // const { productId } = useParams();
    const location = useLocation();
    const [message, setMessage] = useState(location.state !== null ? location.state.message : null);
    const [messageType, setMessageType] = useState(location.state != null ? location.state.type : null);
    const setGlobalMessage = (message, type) => {
        setMessage(message);
        setMessageType(type);
    }

    // @eric: als je code kopieert verwijderen wat je niet nodig hebt! dit geeft errors!
    // const [title, setTitle] = useState(null);
    // const section = parents.indexOf('/intern') === -1 ? 'extern' : 'intern';

    // if (title === null || title === undefined) {
    //     getPageTitle('product', productId, setTitle);
    // }

    return (
        <div>
            <PageTopbar
                parents={parents}
                current={current}
            />

            <PageHeader title={title}
                parents={parents}
                current={current}
                message={message}
                messageType={messageType}
                tabGroup="inspections"
            />

            <AppInspectionsActions
                current={current}
                redirectPath={"/" + parents}
                setGlobalMessage={setGlobalMessage} />
        </div>
    )
}