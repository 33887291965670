import React, { useState} from "react";
import { useParams, useLocation } from "react-router-dom";
import { PageHeader } from "../../components/Page/Header";
import { PageTopbar } from "../../components/Page/Topbar";
////import { Identity } from "../../components/Identity";
import { AppTransportDocuments } from "../../app/Transport/Documents";

export function RoutesTransportDocuments({title, parents, current}) {

    let { transportId } = useParams();
    const location = useLocation();
    const [ pageTitle, setPageTitle ] = useState(title);
    const {message} = useState((location.state !== null 
        ? location.state.message 
        : null));
    const {messageType} = useState((location.state !== null 
        ? location.state.type 
        : null));

    return (
        <div>
            {/* <Identity /> */}
            <PageTopbar 
                parents={parents}
                current={current}
            />
            <PageHeader title={pageTitle} parents={parents} current={current} message={message} messageType={messageType} />
            <AppTransportDocuments transportId={transportId} setPageTitle={setPageTitle} current={current} />
        </div>
    );
}