import React, { useState } from "react";
import { useParams, useLocation } from "react-router-dom";
////import { Identity } from "../../components/Identity";
import { AppProductsDetails } from "../../app/Products/Details";
import { PageHeader } from "../../components/Page/Header";
import { PageTopbar } from "../../components/Page/Topbar";
import { getPageTitle } from "../../app/helpers/global";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function RoutesProductsDetails({ parents, current }) {
    const { productId } = useParams();
    const location = useLocation();
    const [message, setMessage] = useState(location.state !== null ? location.state.message : null);
    const [messageType, setMessageType] = useState(location.state != null ? location.state.type : null);

    const setGlobalMessage = (message, type) => {
        setMessage(message);
        setMessageType(type);
    }
    const [title, setTitle] = useState(null);

    if (title === null || title === undefined) {
        getPageTitle('product', productId, setTitle);
    }

    return (
        <div>
            <PageTopbar
                parents={parents}
                current={current}
            />
            <PageHeader title={title}
                parents={parents}
                current={current}
                message={message}
                messageType={messageType}
                tabGroup={"details" + parents.split('/')[1]}

                variables={{ productId: productId }} />

            <AppProductsDetails
                current={current}
                section={parents.split('/')[1]}
                setGlobalMessage={setGlobalMessage} />
        </div>
    )
}