import React, { useState } from "react";
import { ValidateResponse } from "../../app/helpers/global";
import { fetchCustomers} from "../../app/helpers/addossiers";
import { FormFields } from "../../components/Form/Fields";
import { FormInputSubmit } from "../../components/Form/Input/Submit";
import { ListPagination } from "../../components/List/Pagination";
import { List } from "../../components/List";
import { returnValues, removeValue, validateData } from "../../components/Form/Formfunctions";
import { ContentWrapper } from "../../components/Content/Wrapper";
import { Message } from "../../components/Message";

export function AppAddossiersSearchcustomer({ current, setGlobalMessage }) {

    let pageSize = 100;
    const [data, setData] = useState();
    const [totalCount, setTotalCount] = useState();
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState();
    const [isSubmitted, setSubmitted] = useState(false);
    const [pageNr, setPageNr] = useState(1);

    let formFields = {
        surname: {
            type: "text",
            label: 'Achternaam',
            value: values['surname'],
        },
        birthDate: {
            type: "date",
            label: "Geboortedatum",
            options: { min: "-125", max: null },
            value: values['birthDate'],
        }
    }

    let columns = [
        { key: "fullName", type: "link", path: "/addossiers/details/:userId", id: "userId", hideView: "large" },
        { key: "fullName", label: "Naam", type: "text" },
        { key: "birthDate", label: "Geboortedatum", type: "text" },
        { key: "address", label: "Adres", type: "text" },
        { key: "debtorNumber", label: "Debtornummer", type: "text" },
        { key: "userId", label: "", type: "link", path: "/addossiers/details/:userId", id: "userId", hideView: "small" }
    ];

    const setInputValues = (key, value, identifier = null) => {

        // reset errors
        if (isSubmitted === true) {
            setSubmitted(false);
        }
        if (errors) {
            setErrors(removeValue(errors, key, identifier));
        }

        setValues(returnValues(values, key, value, identifier));
    }

    const setInputErrors = (key, value, identifier = null) => {

        // add or remove error
        let newErrors = errors;
        if (value !== undefined && value !== null) {
            newErrors = returnValues(errors, key, value, identifier);
        }
        else {
            newErrors = removeValue(errors, key, identifier);
        }
        setErrors(newErrors);
    }

    const fetchData = (values) => {

        setGlobalMessage(null);
        setTotalCount();
        setData();

        var filterData = values;

        const callData = {
            filters: {
               // sort: sort,
                limitOffset: pageSize * pageNr - (pageSize - 1),
                limitRows: pageSize,
                ...filterData
            }
        }

        fetchCustomers(callData).then((res) => {
            const response = ValidateResponse(res.data, 'customers');

            if (response.status === 'error') {
                setGlobalMessage(response.message, response.status);
            }
            else {
                setData(res.data.customers);
                setTotalCount(res.data.totalCount);
            }
        }).catch((error) => {
            console.log(error);
            const response = ValidateResponse(error, 'status');
            setGlobalMessage((response.message ?? 'Er is iets foutgegaan met het ophalen van de gegevens'), 'error');
        })
    }

    const onSubmit = () => {

        setPageNr(1);
        setSubmitted(true);
        let validation = validateData(formFields, values, true);

        if (validation.status !== 'success' || (!values.surname && !values.birthDate)) {
            setErrors(validation.errors);
            return;
        }

        fetchData(values);
    }

    const handlePagination = (page) => {
        setPageNr(page);
        fetchData = (values);
    }

    const content = () => {
        return (<div className="text-component">
            <FormFields
                formFields={formFields}
                setValues={setInputValues}
                setErrors={setInputErrors}
                formValues={values}
                formErrors={errors}
                //identifier = null
                isSubmitted={isSubmitted}
            />

            <FormInputSubmit handleSubmit = {onSubmit} submitLabel = "Zoeken" />

        </div>
        )
    }


    return (<div>

        <ContentWrapper type='outline-box' content={content()} />

        {data !== 'NULL' && data !== undefined && data.length > 0 &&
            <>
                <List
                    columns={columns}
                    data={data}
                    identifier="customers"
                />
                <ListPagination
                    className="pagination-bar"
                    currentPage={pageNr}
                    totalCount={totalCount}
                    pageSize={pageSize}
                    setCurrentPage={handlePagination}
                />
            </>
        }

        {totalCount === 0 &&
            <Message type = "noData" />
        }
    </div>
    );

}