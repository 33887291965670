import React, { useState } from "react";
import { useLocation } from "react-router-dom";
//import { Identity } from "../../../components/Identity";
import { AppProductsOverview } from "../../../app/Products/Overview";
import { PageHeader } from "../../../components/Page/Header";
import { PageTopbar } from "../../../components/Page/Topbar";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function RoutesProductsOverviewExtern({ title, parents, current }) {
    let identifier = parents.replace('/', '-') + "" + current;
    const location = useLocation();
    const [message, setMessage] = useState(location.state !== null ? location.state.message : null);
    const [messageType, setMessageType] = useState(location.state != null ? location.state.type : null);
    const setGlobalMessage = (message, type) => {
        setMessage(message);
        setMessageType(type);
    }

    const [filterFields, setFilterFields] = useState([]);
    // const [filterValues, setFilterValues] = useState(JSON.parse(localStorage.getItem(identifier)) ?? {});
    const [filterValues, setFilterValues] = useState({});
    const [refresh, setRefresh] = useState(false);

    const resetFilterValues = (values) => {
        setFilterValues(values);
        setRefresh(refresh === false);
    }

    return (
        <div>
            {/* <Identity /> */}
            <PageTopbar
                parents={parents}
                current={current}
            />
            <PageHeader title={title}
                parents={parents}
                current={current}
                message={message}
                messageType={messageType}
                tabGroup="products"
                filterFields={filterFields}
                setFilterValues={resetFilterValues}
                identifier={identifier}
            />

            <AppProductsOverview
                current={current}
                setGlobalMessage={setGlobalMessage}
                overviewType='Extern'
                filterValues={filterValues}
                setFilterFields={setFilterFields}
                identifier={identifier}
            />
        </div>
    )
}