import React, { useState } from "react";
import { PageTopbar } from "../../components/Page/Topbar";
import { PageHeader } from "../../components/Page/Header";
import { AppTransportOverview } from "../../app/Transport/Overview";
import { useLocation } from "react-router-dom";
////import { Identity } from "../../components/Identity";

export function RoutesTransportOverview({ title, parents, current }) {

    let identifier = parents.replace('/', '-') + "-" + current;

    const location = useLocation();
    const [message, setMessage] = useState(location.state !== null ? location.state.message : null);
    const [messageType, setMessageType] = useState(location.state != null ? location.state.type : null);
    const [filterFields, setFilterFields] = useState([]);
    const [filterValues, setFilterValues] = useState(JSON.parse(localStorage.getItem(identifier)) ?? {});
    const [refresh, setRefresh] = useState(false);

    const resetFilterValues = (values) => {
        setFilterValues(values);
        setRefresh(refresh === false);
    }

    const setGlobalMessage = (message, type) => {
        setMessage(message);
        setMessageType(type);
    }

    const handleFilterFields = (newFields) => {
        if (filterFields.length === 0) {
            setFilterFields(newFields);
        }
    }

    return (
        <div>
            {/* <Identity /> */}
            <PageTopbar 
                parents={parents}
                current={current}
            />
            <PageHeader
                title={title}
                parents={parents}
                current={current}
                tabGroup="transport-overview"
                message={message}
                messageType={messageType}
                filterFields={filterFields}
                setFilterValues={resetFilterValues}
                identifier={identifier}
            />

            <AppTransportOverview
                current={current}
                setGlobalMessage={setGlobalMessage}
                filterValues={filterValues}
                setFilterFields={handleFilterFields}
                identifier={identifier}
            />
        </div>
    );
}