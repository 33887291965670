import React, { useState, useEffect } from "react";
import { ValidateResponse } from "../helpers/global";
import { fetchStopProcessData, processTransportStop } from "../helpers/transport";
import { useNavigate } from "react-router-dom";
import { FormFields } from "../../components/Form/Fields";
import { FormInputSubmit } from "../../components/Form/Input/Submit";
import { ContentWrapper } from "../../components/Content/Wrapper";
import { AppTransportProcessRequest } from "./Process/Request";
import { Message } from "../../components/Message";

export function AppTransportProcess({ stopId, setPageTitle, setGlobalMessage }) {
    const navigate = useNavigate();
    const [state, setState] = useState(null);
    const [dataValues, setDataValues] = useState({});
    const [isSubmitted, setSubmitted] = useState(false);
    const [isEscalated, setEscalated] = useState();
    const [isChecked, setChecked] = useState(false);
    const [acceptErrors, setAcceptErrors] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState();
    const [dateError, setDateError] = useState();
    const [formErrors, setFormErrors] = useState({});

    const mergeArray = (array1, array2) => {
        let merged = []

        let index = 0;
        for (let i = 0; i < array1.length; i++) {
            merged[index] = array1[i];
            index++;
        }

        for (let i = 0; i < array2.length; i++) {
            merged[index] = array2[i];
            index++;
        }
        return merged;
    }

    const setFormValues = (key, value, identifier) => {

        let formValues = dataValues;

        if (identifier === "formData") {
            formValues[identifier] = formValues[identifier] ?? {}
            formValues[identifier][key] = value;

        }
        else {
            formValues.requests[identifier] = formValues.requests[identifier] ?? {}
            formValues.requests[identifier][key] = value;
        }
        setDataValues(formValues);
    }

    const initializeData = (fieldKey, fieldData, returnData) => {

        let required = (fieldData.rules !== undefined && fieldData.rules.includes('required'));

        if (returnData.required === undefined) {
            returnData.required = {};
        }
        returnData.required[fieldKey] = required; // required fields are validated on submit (handleSubmit)

        if (fieldData.value) {
            returnData[fieldKey] = fieldData.value;
        }
        else if (required === true) {
            if (returnData.errors === undefined) {
                returnData.errors = {};
            }
        }
        return returnData;
    }

    useEffect(() => {

        fetchStopProcessData(stopId).then((res) => {

            const response = ValidateResponse(res.data, 'stop');

            if (response.status === 'error') {
                setError(response.message);
                return;
            }
            else {
                let data = response.stop;
                setPageTitle(data.title ?? data.transportName);

                // set (default data)
                let defaultValues = { formData: {}, requests: {} }
                if (dataValues === null || (Object.keys(dataValues).length === 0)) {

                    // Initialize data for stop
                    if (typeof data.formFields === 'object' && Object.keys(data.formFields).length > 0) {
                        Object.keys(data.formFields).map((fieldKey) => (
                            defaultValues.formData = initializeData(fieldKey, data.formFields[fieldKey], defaultValues.formData)
                        ));
                    }

                    // Initialize data for requests
                    let allRequests = mergeArray(data.productIN, data.productOUT);
                    if (allRequests !== undefined) {
                        allRequests.forEach((request) => {
                            if (typeof request.formFields === 'object' && Object.keys(request.formFields).length > 0) {

                                // required field processData
                                let returnData = initializeData("processStatus", { rules: ["required"] }, {})

                                Object.keys(request.formFields).forEach((fieldKey) => {
                                    let transporRequestId = request.transportRequestId;
                                    let fieldData = request.formFields[fieldKey];
                                    defaultValues.requests[transporRequestId] = initializeData(fieldKey, fieldData, returnData);
                                });
                            }
                        });
                    }
                }

                setDataValues(defaultValues);
                setState(data);
                setIsLoaded(true);
            }
        }).catch((error) => {
            let response = ValidateResponse(error);
            setError(response.message);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setState, stopId, navigate])

    // if (error !== undefined) {
    //     return (<Message type="error" message={error} />);
    // }

    if (isLoaded === false) {
        return (<Message type="loading" />);
    }

    if (typeof state !== 'object') {
        return (
            <Message type="error" message="Het door u opgevraagde transport kan helaas niet gevonden worden." />
        );
    }

    // state is set in setState(), above
    let customFields = null;
    if (typeof state.formFields === 'object' && Object.keys(state.formFields).length > 0) {
        customFields = state.formFields;
    }

    const checkAll = () => {
        let setTo = isChecked === true ? false : true;
        let requestValues = dataValues.requests ?? {};

        let allRequests = mergeArray(state.productIN, state.productOUT);

        allRequests.map((request) => {
            if (requestValues[request.transportRequestId] === undefined) {
                requestValues[request.transportRequestId] = {};
            }
            requestValues[request.transportRequestId].processStatus = "OK";
            return requestValues;
        });
        setChecked(setTo);
    }

    const getDataErrors = (validateData, skipRequired) => {

        let requiredErrors = validateData.errors ?? {};

        if (skipRequired === true) {

            if (requiredErrors.errorProcess !== undefined) {
                setEscalated(true);
            }
            return Object.keys(requiredErrors).length === 0 ? undefined : requiredErrors;
        }

        if (validateData.required !== undefined) {

            Object.keys(validateData.required).map((fieldKey) => {

                if (validateData.required[fieldKey] === true && !validateData[fieldKey]) {

                    if (validateData.processStatus !== "ERROR") {
                        requiredErrors[fieldKey] = "Verplicht veld";
                    }
                }
                return Object.keys(requiredErrors).length === 0 ? undefined : requiredErrors;
            });
        }

        // check conditional fields
        if (validateData.serialFieldset !== undefined && validateData.processStatus !== "ERROR") {
            if (validateData.serialFieldset === "productId" && !validateData.productId) {
                requiredErrors['productId'] = "Verplicht veld"
            }
            else if (validateData.serialFieldset === "serialNumber" && !validateData.serialNumber) {
                requiredErrors['serialNumber'] = "Verplicht veld"
            }
        }

        return Object.keys(requiredErrors).length === 0 ? undefined : requiredErrors;
    }

    const handleSubmit = () => {

        let hasErrors = false;
        let resetValues = dataValues;

        // Skip required fields on escalation
        let skipRequired = null
        Object.values(dataValues.requests).forEach((request) => {

            if (request.processStatus === 'ERROR' && skipRequired !== false) {
                skipRequired = true;
            }
            else {
                skipRequired = false;
            }
        });

        if (isSubmitted === false && skipRequired !== true) {
            setSubmitted(true);
        }

        let dataErrors = getDataErrors(dataValues.formData, skipRequired);

        if (dataErrors !== undefined) {
            hasErrors = true;
            resetValues.formData.errors = dataErrors;
        }

        let postRequests = [];
        let allRequests = mergeArray(state.productIN, state.productOUT);

        allRequests.map((request) => {

            let requestValues = dataValues.requests[request.transportRequestId] ?? {};
            let requestErrors = getDataErrors(requestValues, skipRequired);

            if (requestErrors !== undefined) {
                hasErrors = true;
                resetValues.requests[request.transportRequestId].errors = requestErrors;
            }

            let reqestFormData = {};
            if (typeof request.formFields === 'object' && Object.keys(request.formFields).length > 0) {
                Object.keys(request.formFields).map((fieldName) => (
                    reqestFormData[fieldName] = requestValues[fieldName] ?? null
                ));
                if (requestValues.serialFieldset !== undefined) {
                    reqestFormData[requestValues.serialFieldset] = requestValues[requestValues.serialFieldset];
                }
            }

            if(requestValues.processStatus === 'OKREMARKS'){
                requestValues.processStatus = 'OK';
            }

            let requestItem = {
                transportRequestId: request.transportRequestId,
                type: request.processType,
                status: requestValues.processStatus,
                formData: reqestFormData,
            }

            if(requestValues.remarks !== undefined && requestValues.remarks !== null && requestValues.remarks.trim() !== ''){
                requestItem.remarks = requestValues.remarks;
            }

            if (requestItem.status === 'ERROR') {
                requestItem.error = {
                    amount: requestValues.errorAmount,
                    process: requestValues.errorProcess,
                    remarks: requestValues.errorRemarks,
                }
            }
            postRequests.push(requestItem);

            return postRequests;
        });

        if (hasErrors === true) {
            setDataValues(resetValues);
            return;
        }

        // Cleanup Formdata
        let formData = dataValues.formData ?? {};
        if (formData.required !== undefined) {
            delete formData.required;
        }

        let postValues = {
            'stopId': stopId,
            'requests': postRequests,
            'formData': formData,
            'acceptErrors': acceptErrors
        }

        let documentTypes = []

        if (state.documents != null) {
            Object.keys(state.documents).map((option, index) => (
                documentTypes.push(state.documents[option])
            ));
        }

        processTransportStop(postValues).then((res) => {

            const response = ValidateResponse(res.data);

            if (response.status === 'error') {
                if (response.dateError !== undefined) {
                    setDateError(response.message);
                    return;
                } else {
                    setError(response.message);
                    return;
                }
            }
            else {
                if (documentTypes.length > 0 && state.isInternal !== true) {
                    if (documentTypes.length > 1) {
                        navigate("/transport/active/documents/" + stopId, {
                            state: {
                                documentTypes: documentTypes,
                                message: response.message,
                                type: response.status
                            }
                        });
                    } else {
                        navigate("/transport/active/sign/" + stopId, {
                            state: {
                                documentType: documentTypes[0].type,
                                message: response.message,
                                type: response.status
                            }
                        });
                    }
                } else {
                    navigate("/transport/active/details/" + state.transportId, {
                        state: {
                            message: response.message,
                            type: response.status
                        }
                    });
                }
            }
        }).catch((error) => {
            let response = ValidateResponse(error);
            setError(response.message);
        })
    }

    let toProcess = mergeArray(state.productIN, state.productOUT);

    const content = () => {
        return (
            <>
                <h3>{state.stopName}</h3>
                {customFields &&
                    <FormFields
                        formFields={customFields}
                        setValues={setFormValues}
                        formValues={dataValues.formData}
                        setErrors={setFormErrors}
                        formErrors={formErrors}
                        identifier="formData"
                        isSubmitted={isSubmitted}
                    />
                }
                {dateError !== undefined &&
                    <div className="form__row form__row--bb">
                        <div className="form__component">
                            <div className="form__element">
                                <input type="checkbox" id="acceptErrors" defaultChecked={acceptErrors} onChange={() => { setAcceptErrors(!acceptErrors) }} />
                                <label htmlFor="acceptErrors">Accepteer verwerkingsdatum</label>
                            </div>
                        </div>
                    </div>
                }

                <div className="form__row form__row--bb">
                    <div className="form__component">
                        <div className="form__element">
                            <input type="checkbox" id="checkAll" defaultChecked={isChecked} onChange={() => { checkAll() }} />
                            <label htmlFor="checkAll">Alles succesvol in/uitgeladen</label>
                        </div>
                    </div>
                </div>

                {toProcess.map((request) =>
                    <div key={"process-request-" + request.transportRequestId}>

                        <AppTransportProcessRequest
                            setValues={setFormValues}
                            customValues={dataValues}
                            isSubmitted={isEscalated ?? isSubmitted}
                            request={request}
                            identifier={request.transportRequestId}
                            formFields={request.formFields}
                            allChecked={isChecked}
                        />
                    </div>)
                }

                <FormInputSubmit
                    handleSubmit={handleSubmit}
                    cancelUri={"/transport/active/details/" + state.transportId}
                    label="Opslaan"
                />

            </>
        );
    }

    return (
        <>
            <Message type="error" message={error} />
            {dateError !== undefined &&
                <Message type="error" message={dateError} />
            }
            <ContentWrapper content={content()} type="outline-box" />
        </>
    );
}