import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ValidateResponse} from "../../../helpers/global";
import { fetchAllowedInspectionTypes, processCreateInspection } from "../../../helpers/inspections";
import { Message } from "../../../../components/Message";
import { ContentWrapper } from "../../../../components/Content/Wrapper";
import { returnValues, removeValue, validateData } from "../../../../components/Form/Formfunctions";
import { FormFields } from "../../../../components/Form/Fields";
import { FormInputSubmit } from "../../../../components/Form/Input/Submit";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function AppProductsDetailsInspectionsCreate({ productId }) {

    const [error, setError] = useState();
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState();
    const [isSubmitted, setSubmitted] = useState(false);
    const [formFields, setFormFields] = useState({})


    useEffect(() => {
        fetchAllowedInspectionTypes(productId).then((res) => {

            const response = ValidateResponse(res.data, 'inspectionTypes');
            if (response.status === 'success') {

                let formFields = {
                    inspectionType: {
                        type: "select",
                        label: 'Keuringstype',
                        value: values['inspectionType'],
                        options: response.inspectionTypes
                    },
                    plannedDate: {
                        type: "date",
                        label: "Geplande keuringsdatum",
                        options: { min: null, max:"+1" },
                        value: values['plannedDate'],
                    }
                }
                setFormFields(formFields);
            }
            else {
                setError(response.message);
            }
        })
    },[productId])

    const setInputValues = (key, value, identifier = null) => {

        // reset errors
        if (isSubmitted === true) {
            setSubmitted(false);
        }
        if (errors) {
            setErrors(removeValue(errors, key, identifier));
        }

        setValues(returnValues(values, key, value, identifier));
    }

    const setInputErrors = (key, value, identifier = null) => {

        // add or remove error
        let newErrors = errors;
        if (value !== undefined && value !== null) {
            newErrors = returnValues(errors, key, value, identifier);
        }
        else {
            newErrors = removeValue(errors, key, identifier);
        }
        setErrors(newErrors);
    }

    const onSubmit = () => {
        setSubmitted(true);

        let validation = validateData(formFields, values, true);

        if (validation.status !== 'success' || (!values.inspetionTypes && !values.plannedDate)) {
            setErrors(validation.errors);
            return;
        }

        const callData = {
            productId:productId,
            ...values
        }

        processCreateInspection(callData).then((res) => {
            const response = ValidateResponse(res.data);
            if (response.status === 'success') {
                console.log(response);
            }
            else {
                setError(response.message);
            }
        })


    }

    const content = () => {
        return (<div className="text-component">
           <FormFields
                formFields={formFields}
                setValues={setInputValues}
                setErrors={setInputErrors}
                formValues={values}
                formErrors={errors}
                //identifier = null
                isSubmitted={isSubmitted}
            />

            <FormInputSubmit handleSubmit = {onSubmit} submitLabel = "Verzenden" />
        </div>
        )
    }


    if (error !== undefined) {
        return (<Message type="error" message={error} />);
    }

    return (
        <>
            <ContentWrapper type='outline-box' content={content()} />
        </>
    );
}