/**
 * Service index located in Public/Service/Pwa
 * Interal app routes to /Adm
 * Client app to /Pwa
 * Controllers can be  Controllers/Service
 * 
 * if (process.env.REACT_APP_API_URL !== undefined) {
    api_url = 'https://' + process.env.REACT_APP_API_URL + '/Rest/Pwa';
}
else {
    api_url = 'https://api.vandermarkzorghulpmiddelen.nl/Rest/Pwa';
}

 */
let api_url = null;
let image_url = null;
if (process.env.REACT_APP_API_URL !== undefined) {
    let apiservice = process.env.REACT_APP_APISERVICE ? process.env.REACT_APP_APISERVICE : 'Adm';
    api_url = 'https://' + process.env.REACT_APP_API_URL + '/Rest/' + apiservice;
    image_url = api_url + '/image.php';
}
else {
    api_url = 'https://api.vandermarkzorghulpmiddelen.nl/Rest/Adm';
    image_url = 'https://api.vandermarkzorghulpmiddelen.nl/Rest/Adm/image.php';
}

export const API_URL = api_url;
export const IMAGE_URL = image_url;

const getApiHeaders = () => {
    let headers = {
        'Content-Type': 'application/json',
        //'X-Version': process.env.REACT_APP_VERSION //@todo: uitzoeken waarom dit CORS error geeft  en waarom hij soms niet meegestuurd wordt
    }
    const user = JSON.parse(localStorage.getItem('auth'))
    if (user && user.token) {
        headers = { ...headers, 'X-Auth-Token': user.token }
    }

    return { headers: headers };
}

const getApiHeadersPdf = () => {
    let headers = {
        'Content-Type': 'application/json',
        //'X-Version': process.env.REACT_APP_VERSION
    }
    const user = JSON.parse(localStorage.getItem('auth'))
    if (user && user.token) {
        headers = { ...headers, 'X-Auth-Token': user.token }
    }

    return { responseType: 'blob', headers: headers }
}


export{
    getApiHeaders,
    getApiHeadersPdf
}
