import React, { useState } from "react";
import { useParams } from "react-router-dom";
//import { Identity } from "../../../components/Identity";
import { AppProductsDetailsDocuments } from "../../../app/Products/Details/Documents";
import { PageHeader } from "../../../components/Page/Header";
import { PageTopbar } from "../../../components/Page/Topbar";
import { getPageTitle } from "../../../app/helpers/global";
/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function RoutesProductsDetailsDocuments({ parents, current }) {
    const { productId } = useParams();
    const [title, setTitle] = useState(null);

    if (title === null || title === undefined) {
        getPageTitle('product', productId, setTitle);
    }

    return (
        <div>
            <PageTopbar
                parents={parents}
                current={current}
            />
            <PageHeader title={title}
                parents={parents}
                current={current}
                tabGroup={"details" + parents.split('/')[1]}
                variables={{ productId: productId }} />

            <AppProductsDetailsDocuments
                productId={productId} />
        </div>
    )
}