/**
 * @see helpers/global
 * 
 * These functions are the same for intern & client and can be copied/overwritten by one another
 * Note that the API_URL (set in api/js) is different.
 */
import axios from 'axios';
import { Route } from "react-router-dom";
import { API_URL, getApiHeaders } from "./api";
import { getRoutesConfig } from "../routes/config";

// GET
const checkIdentity = () => axios.get(
    `${API_URL}/auth/identify`, getApiHeaders()
)
// POST
const userLogin = formData => axios.post(
    `${API_URL}/auth/login`, formData, getApiHeaders()
);
const sendPasswordResetMail = formData => axios.post(
    `${API_URL}/auth/forgot`, formData, getApiHeaders()
);
const checkGuid = formData => axios.post(
    `${API_URL}/auth/checkGuid`, formData, getApiHeaders()
);
const setAccountPassword = formData => axios.post(
    `${API_URL}/auth/setPassword`, formData, getApiHeaders()
);


// Auth functions

// //
// export function useVersionCheck(state) {

//     const navigate = useNavigate();
//     let timestamp = new Date().getTime();
//     let pageParts = window.location.pathname.split("/");
//     let pageKey = 'version-' + pageParts[1];
//     if (pageParts[2] !== undefined) {
//         pageKey += '-' + pageParts[2]
//     }

//     if (localStorage.getItem(pageKey) === null) {
//         localStorage.setItem(pageKey, JSON.stringify(
//             { "path": window.location.pathname, "counter": 0, "timestamp": timestamp }));
//     }

//     let versionCheck = JSON.parse(localStorage.getItem(pageKey));
//     let secondSincelastHit = (timestamp - versionCheck.timestamp) / 1000;

//     // reset pagekey after 1 hour
//     if (secondSincelastHit > 3600) {
//         localStorage.setItem(pageKey, JSON.stringify(
//             { "path": window.location.pathname, "counter": 0, "timestamp": timestamp }));
//     }

//     let counter = parseInt(versionCheck.counter);

//     if (parseInt(secondSincelastHit) > 1 && counter < 100) {

//         checkVersion({ "version": process.env.REACT_APP_VERSION, "path": window.location.pathname, "timestamp": timestamp })
//             .then((versionResponse) => {

//                 let reload = false;
//                 let type = null;

//                 if (versionResponse.data.status === undefined) {
//                     reload = true;
//                     type = 'error';
//                 }
//                 else if (versionResponse.data.status !== 'success') {
//                     reload = true;
//                     type = 'error';
//                 }

//                 if (reload === true) {
//                     versionCheck.counter++;
//                     versionCheck.timestamp = timestamp;
//                     localStorage.setItem(pageKey, JSON.stringify(versionCheck));
//                     navigate(window.location.pathname + '?' + timestamp, { replace: true, state: state + { message: versionResponse.data.message, type: type } });
//                 }
//             }).catch((error) => {
//                 console.log(error);
//             }
//         );
//     }
// }

const pathToKey = (path) => {
    let key = path.replaceAll('/', '_').replaceAll(':', '_');

    if (key.substr(0, 1) === '_') {
        key = key.substr(1);
    }
    return key;
}

/**
 *
 * @param children
 * @param basePath
 * @return {*}
 */
const mapRoutes = (children, cascase = true, basePath = "", handlers) => {

    return children.map((route) => {

        let uniqueKey = Math.random() * 10000000;

        const path = route.fullPath ?? (basePath + route.path);

        let routeComponentElement = (Component) => (
            Component && <Component.type {...Component.props} {...handlers} />
        );

        let routeComponent = <Route key={uniqueKey + 1} path={path} element={routeComponentElement(route.component)} />;

        if (!route.component && route.redirect) {
            routeComponent = <Route key={uniqueKey + 2} path={path} element={route.redirect} />
        }

        if (!route.children || route.children.length < 1) {
            return routeComponent;
        }
        if (cascase === true) {
            return (
                <Route key={uniqueKey + 3} path={path}>
                    {routeComponent}
                    {mapRoutes(route.children, cascase, path)}
                </Route>
            );
        }
    })
}

/**
 */
const buildBaseStructure = (routeList, structure = {}, fullPath = "") => {

    if (structure.routes === undefined) {
        structure.routes = {};
    }
    if (structure.menu === undefined) {
        structure.menu = [];
    }

    let auth = JSON.parse(localStorage.getItem("auth"));

    if (auth === null || auth === undefined || auth.permissions === undefined) {
        return structure;
    }

    routeList.forEach((entry) => {

        let myPath = fullPath + entry.path;
        let permissionLevel = getPermissionLevel(myPath);
        let pageLevel = entry.permissionLevel ?? 1;

        if (permissionLevel >= pageLevel) {

            let pathKey = pathToKey(myPath);

            entry.fullPath = myPath;
            structure.routes[pathKey] = entry;

            if (entry.topMenu === true) {
                structure.menu.push({ path: myPath, title: entry.pageTitle });
            }

            if (entry.children !== undefined) {
                structure = buildBaseStructure(entry.children, structure, myPath);
            }
        }
    });

    return structure;
}

const getBaseStructure = (auth = null) => {

    let structure = {};
    if (auth === null) {
        auth = JSON.parse(localStorage.getItem('auth'));
    }
    if (auth === null || auth === undefined || auth.roles === undefined) {
        return {};
    }

    let routesConfig = getRoutesConfig();

    routesConfig.protected.forEach((list) => {
        structure = buildBaseStructure(list, structure);
    });

    localStorage.setItem("main-menu", JSON.stringify(structure.menu));
    localStorage.setItem("routes-tree", JSON.stringify(structure.routes));

    return structure;
}

/** public|protected|all */
const getRoutesLists = (listType) => {
    let routesList = [];
    let identifiers = [];
    let routesConfig = getRoutesConfig();

    if (listType === 'public' || listType === 'all') {
        routesConfig.public.forEach((list) => {

            let listId = list[0].identifier ?? null;
            if (identifiers.includes(listId) === false) {
                routesList = [...routesList, ...list];
            }
            identifiers.push(listId);
        });
    }
    if (listType === 'protected' || listType === 'all') {
        routesConfig.protected.forEach((list) => {

            let listId = list[0].identifier ?? null;
            if (identifiers.includes(listId) === false) {
                routesList = [...routesList, ...list];
            }
            identifiers.push(listId);
        });
    }

    return routesList;
}

// checkPermission by route #HIERBWENIK: check tiles onder product ... routes path key?
const validateRoute = (path) => {

    let routes  = JSON.parse(localStorage.getItem("routes-tree"));
    let pathKey = pathToKey(path);
    let route   = routes[pathKey];

    if (route !== undefined) {
        return true;
    }

    return false;
}

/**
 * levels: 0= no access, 1=read permission, 2=read+write permission
 */
const getPermissionLevel = (path) => {

    let level = 0;
    let auth = JSON.parse(localStorage.getItem("auth"));
    if(auth !== undefined && auth !== null) {

        if (path === '' || path === '/' || path === '*' || path === '/home') {
            level = 1; 
        }
        else {
            Object.keys(auth.permissions).forEach((permission) => {

                let checkPath = path.substring(1) + "/";
                
                if (permission === checkPath.substring(0, (permission.length))) {
                    level = auth.permissions[permission];
                }
            });
        }
    }

    return level;
}

export {
    // Api
    checkIdentity,
    userLogin,
    sendPasswordResetMail,
    checkGuid,
    setAccountPassword,

    // functions
    mapRoutes, // kan weg? @see buildAuthObject
    getBaseStructure,
    getRoutesLists,
    validateRoute
}