import React, { useState } from "react";
import { FormFields } from "../../../components/Form/Fields";

/**
 * Default form elemeent parameters:
 * * setValues (function) needs to be set in parent to transfer data values & errors
 * * isSubmitted (bool) to mark if the "submit" button is clicked, to raise errors on empty required fields
 * * required (bool)
 * 
 * Custom parameters:
 * * request (object) the transport 
 * * identifier (int) the transportRequestId
 * * isSuccess (bool) to set all requests to OK (overrides processStatus)
 * * formFields (object) to process customfields
 * * setStatus (string) if OK, set processStatus to OK
 */
export function AppTransportProcessRequest({ setValues, customValues, isSubmitted = false, required = true, request, identifier, formFields, allChecked = null }) {

    const [checkAll, setCheckedAll] = useState(allChecked);
    const [processStatus, setProcessStatus] = useState(null);
    const [errorProcess, setErrorProcess] = useState(null);
    const [formErrors, setFormErrors] = useState({});

    // alles successvol inladen (kan later handmatig ongedaan gemaakt worden)
    if (allChecked !== checkAll) {
        setCheckedAll(allChecked);
        if (allChecked === true && processStatus !== "OK") {
            setProcessStatus('OK');
        }
    }

    let labelPrefix = request.processType === 'PICKUP' ? "in" : "uit";
    let labelPrefixCap = request.processType === 'PICKUP' ? "In" : "Uit";

    let processOptions = {
        OK: "Succesvol " + labelPrefix + "geladen",
        OKREMARKS: labelPrefix.charAt(0).toUpperCase() + labelPrefix.slice(1) + "geladen met opmerkingen",
        ERROR: "Fout bij " + labelPrefix + "laden"
    };

    let escalationOptions = {
        ESCALATION: "Escaleren naar oorsprong",
        REQUEST: "Terugzetten naar transportverzoeken"
    };

    const handleChange = (event) => {
        
        let name = event.target.dataset.name;
        let value = event.target.value;

        let requestValues = customValues.requests[identifier] ?? { errors: undefined };

        requestValues[name] = value;

        if (name === "processStatus") {
            setProcessStatus(value);
            delete requestValues.errorProcess;
            if (value === "ERROR") {
                requestValues.errors = { errorProcess: "Verplicht veld" }

            }
        }
        else if (name === "errorProcess") {
            delete requestValues.errors;
            setErrorProcess(value);
        }
        setValues(name, value, identifier);
    }

    const getError = (checkField) => {
        let errorMessage = null;
        if (checkField === 'processStatus' && isSubmitted === true && processStatus === null) {
            errorMessage = 'Verplicht veld';
        }
        if (checkField === 'errorProcess' && isSubmitted === true && errorProcess === null) {
            errorMessage = 'Verplicht veld';
        }
        if (errorMessage !== null) {
            return <span style={{ color: 'red' }}>{errorMessage}: </span>
        }
    }

    const getAmountOptions = (amount) => {
        let options = [];
        for (let i = amount; i !== 0; i--) {
            options.push(i);
        }
        return options;
    }

    return (
        <>
            <div className="form__row">
                <div className="form__component">
                    <div className="details-title">
                        {request.amount}x {request.displayName}
                    </div>
                </div>
            </div>
            <div className="form__row form__row--fieldset form__row--processInput">

                <div className="form__component form__component--auto">
                    <div className="form__label">
                        {getError('processStatus')} {labelPrefixCap}laden:
                    </div>
                </div>

                {Object.keys(processOptions).map((optionKey) =>
                    <div className="form__component form__component--auto" key={"process-status-" + identifier + optionKey}>
                        <div className="form__element">
                            <div className="form__input">
                                <input
                                    type="radio"
                                    id={"option-process-status-" + identifier + optionKey}
                                    value={optionKey}
                                    data-name="processStatus"
                                    checked={processStatus === optionKey}
                                    onChange={handleChange}
                                />
                                <label htmlFor={"option-process-status-" + identifier + optionKey}>{processOptions[optionKey]}</label>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {processStatus === "OKREMARKS" && 
                <div className="form__row">
                <div className="form__component">
                    <label htmlFor="remarks">Opmerkingen</label>
                    <div className="form__element">
                        <div className="form__input">
                            <textarea id="remarks" data-name="remarks" onChange={handleChange} cols="30" rows="10"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            }

            {processStatus === "ERROR" &&
                <>
                    <div className="form__row form__row--fieldset form__row--processInput">
                        <div className="form__component form__component--auto">
                            <div className="form__label">
                                {getError('errorProcess')} Escalatie:
                            </div>
                        </div>

                        {Object.keys(escalationOptions).map((optionKey) =>
                            <div className="form__component form__component--auto" key={"errorProcess-" + identifier + optionKey}>
                                <div className="form__element">
                                    <div className="form__input">
                                        <input
                                            type="radio"
                                            id={"option-errorProcess-" + identifier + optionKey}
                                            value={optionKey}
                                            data-name="errorProcess"
                                            checked={errorProcess === optionKey}
                                            onChange={handleChange}
                                        />
                                        <label htmlFor={"option-errorProcess-" + identifier + optionKey}>{escalationOptions[optionKey]}</label>
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>

                    {request.amount > 1 &&
                        <div className="form__row" >
                            <div className="form__component">
                                <label htmlFor="error-amount">Aantal NIET {labelPrefix}geladen *</label>
                                <div className="form__element">
                                    <div className="form__input">
                                        <select defaultValue={request.amount} data-name="errorAmount" onChange={handleChange}>
                                            {getAmountOptions(request.amount).map((i) =>
                                                <option key={"error-amount-option-" + identifier + "-nr" + i} value={i}>{i}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="form__row">
                        <div className="form__component">
                            <label htmlFor="error-remarks">Fout bij {labelPrefix}laden</label>
                            <div className="form__element">
                                <div className="form__input">
                                    <textarea id="error-remarks" data-name="errorRemarks" onChange={handleChange} cols="30" rows="10"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                </>
            }

            {(processStatus === "OK" || processStatus === "OKREMARKS") && formFields !== undefined &&
                <FormFields
                    formFields={formFields}
                    setValues={setValues}
                    formValues={customValues.requests[request.transportRequestId]}
                    setErrors={setFormErrors}
                    formErrors={formErrors}
                    identifier={identifier}
                    isSubmitted={isSubmitted}
                />
            }
        </>
    )
}