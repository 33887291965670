import axios from 'axios';
import { API_URL, getApiHeaders } from "./api.js"

// POST/GET
const fetchTransports = formData => (
    axios.post(`${API_URL}/admin/transport/overview`, formData, getApiHeaders())
)
const fetchTransportDetails = transportId => (
    axios.get(`${API_URL}/admin/transport/details/` + transportId, getApiHeaders())
)
const fetchStopDetails = stopId => (
    axios.get(`${API_URL}/admin/transport/stop/details/` + stopId, getApiHeaders())
)
const fetchStopProcessData = stopId => (
    axios.get(`${API_URL}/admin/transport/stop/processdata/` + stopId, getApiHeaders())
)

// PATCH/PUT
const processTransportStop = formData => axios.put(
    `${API_URL}/admin/transport/process/stop/` + formData.stopId, formData, getApiHeaders()
);
const processSignature = formData => axios.put(
    `${API_URL}/admin/transport/process/signing`, formData, getApiHeaders()
);

export {
    //POST
    fetchTransports,
    processTransportStop,
    processSignature,
    //GET
    fetchTransportDetails,
    fetchStopDetails,
    fetchStopProcessData
}