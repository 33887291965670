import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Identity } from "../../components/Identity";
import { AppInspectionOverview } from "../../app/Inspections/Overview";
import { PageHeader } from "../../components/Page/Header";
import { PageTopbar } from "../../components/Page/Topbar";
import { AppRecentInspection } from "../../app/Inspections/RecentInspection";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function RoutesInspectionsOverview({ title, parents, current }) {
    let identifier = parents.replace('/', '-') + "" + current;
    const location = useLocation();
    const [message, setMessage] = useState(location.state !== null ? location.state.message : null);
    const [messageType, setMessageType] = useState(location.state != null ? location.state.type : null);
    const setGlobalMessage = (message, type) => {
        setMessage(message);
        setMessageType(type);
    }

    const [filterFields, setFilterFields] = useState([]);
    const [filterValues, setFilterValues] = useState({});
    const [refresh, setRefresh] = useState(false);

    const resetFilterValues = (values) => {
        setFilterValues(values);
        setRefresh(refresh === false);
    }

    return (
        <div>
            <Identity />
            <PageTopbar
                parents={parents}
                current={current}
            />
            <PageHeader title={title}
                parents={parents}
                current={current}
                message={message}
                messageType={messageType}
                filterFields={filterFields}
                tabGroup="inspections"
                setFilterValues={resetFilterValues}
                identifier={identifier}
            />

            {/*  WERK NIET! @todo: fixen!
            <AppRecentInspection
                current={current}
                setGlobalMessage={setGlobalMessage}
                identifier={identifier}
            /> */}

            <AppInspectionOverview
                current={current}
                setGlobalMessage={setGlobalMessage}
                filterValues={filterValues}
                setFilterFields={setFilterFields}
                identifier={identifier}
                inspectionType={'all'}
            />
        </div>
    )
}