/**
 * DEPRECATED: @see authWrapper
 */

import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { checkIdentity } from "../app/helpers/auth";
import { ValidateResponse } from "../app/helpers/global";
import { Message } from "./Message";

export function Identity() {

    const [error, setError] = useState();

    // Check server login + version
    useEffect(() => {
        checkIdentity(true).then((res) => {
            const response = ValidateResponse(res.data);

            if (response.environment != process.env.REACT_APP_NAME) {
                setError("SESSION_EXPIRED");
            }
            if (response.status !== 'success') {
                setError(response.message);
            }
        }).catch((error) => {
            console.log(error);
            const response = ValidateResponse(error);
            setError(response.message);
        })
    });

    // Check react login
    const user = JSON.parse(localStorage.getItem('auth'))
    if (!(user && user.token) || user.environment !== process.env.REACT_APP_NAME) {
        setError("SESSION_EXPIRED");
    }

    if (error !== undefined && error !== null) {
        if (window.location.host === "localhost:3000") {
            return <Message type="error" message={error} />
        }
        else {
            console.log(error);
            return <Navigate to={"/auth/logout"} 
            state={{ message: "Sessie is verlopen", type: "error" }} />
        }
    }

    return <></>
}