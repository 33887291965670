import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ValidateResponse } from "../../helpers/global";
import { getRentableSerialnumberList} from "../../helpers/addossiers";
import { fetchWarehouses } from "../../helpers/global";
import { List } from "../../../components/List";
import { FormFields } from "../../../components/Form/Fields";
import { returnValues, removeValue, validateData } from "../../../components/Form/Formfunctions";
import { ContentWrapper } from "../../../components/Content/Wrapper";
import { Message } from "../../../components/Message";


export function AppAddossiersSelectDossiers ({ userId, processSelect, setGlobalMessage }) {

    const navigate = useNavigate();
    const [dossierList, setdossierList] = useState([]);
    const [dossiers, setDossiers] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState();
    const [error, setPageError] = useState();
    const [isSubmitted, setSubmitted] = useState(false);
    const [refresh, setRefresh ] = useState(false);
    const [isLoaded, setIsLoaded ] = useState(false);

    let formFields = {}
    let marker = '*';
    if (warehouses.length > 0) {
        formFields.warehouse = {
            type: "select",
            label: "Magazijn inname" + marker,
            options: warehouses,
            value: values['warehouse'],
            rules: ["required"]
        }
    }

    // const setFormattedList = (listDossiers) => {
    //     let formatted = [];
    //     listDossiers.forEach((dossier) => {
    //         if (dossier.isChecked !== true) {
    //            formatted.unshift(dossier);
    //         }
    //         else {
    //            formatted.push(dossier);
    //         }
    //     });

    //     setDossiers(formatted);
    //     return formatted;
    // }

    const handleChecklist = (name, value) => {

        let newValues = values;

        if (name.indexOf('productId__') !== -1) {

            let cases = dossiers;
            let selectedCases = dossierList;
            let checkedCase = name.replace('productId__', '');

            if (value === true && selectedCases.includes(checkedCase) === false) {
                selectedCases.push(checkedCase);
            }
            else if (value === false && selectedCases.includes(checkedCase) === true) {
                var index = selectedCases.indexOf(checkedCase);
                if (index !== -1) {
                    selectedCases.splice(index, 1);
                }
            }

            cases.forEach((rental, i) => {
                if (rental.rentalCaseId === checkedCase) {
                    cases[i].isChecked = value;
                }
            });

            setDossiers(cases);
            setdossierList(selectedCases);
            newValues.selectedCases = selectedCases;
        }
        else {
            newValues[name] = value;
        }
        setValues(newValues);
        setRefresh(refresh === false); // @todo uitzoeken waarom pagina niet refreshed op setValues ..
        return;
    }

    useEffect(() => {
        fetchWarehouses().then((res) => {
            const response = ValidateResponse(res.data, 'warehouses');
            if (response.status === 'error') {
                setPageError(response.message);
                //setGlobalMessage(response.message, response.status);
            } else {
                setWarehouses(response.warehouses);
            }
        }).catch((error) => {
            let response = ValidateResponse(error);
            setPageError(response.message);
        })
    }, [setWarehouses, navigate])

    useEffect(() => {
        let processType = 'RETURN';

        let callData = {
            'userId': userId,
            'type': processType
        }

        if (isLoaded === false) {
            getRentableSerialnumberList(callData).then((res) => {
                const response = ValidateResponse(res.data, 'dossiers');
                if (res.data != null) {
                    setDossiers(response.dossiers);
                }
                setIsLoaded(true);
            }).catch((error) => {
                let response = ValidateResponse(error);
                setPageError(response.message);
            })
        }

    }, [setDossiers, navigate, userId, dossiers])

    if (error !== undefined) {
        return (<Message type="error" message={error} />);
    }

    if (dossiers.lenght === 0) {
        return (<Message type="noData" />);
    }

    const handleSelect = () => {

        setSubmitted(true);
        let validation = validateData(formFields, values, true);
        let data = values;
        if (validation.status !== 'success') {
            setErrors(validation.errors);
            let globalMessage = validation.errors.globalMessage ?? 'Het formulier kan niet verzonden worden';
            setGlobalMessage(globalMessage, 'error');
            return;
        }

        let formData = {
            'userId': userId,
            'products': dossierList,
            'warehouseId': data.warehouse
        }

        processSelect(formData);
    }

    let columnsReturn = [
        { key: "productId", label: "", type: "checkbox", valueKey: 'isChecked' /*function: handleCheck*/},
        { key: "name", label: "Naam", type: "text" },
        { key: "serialNumber", label: "Serienummer", type: "text" }
    ];

    const setInputValues = (key, value, identifier = null) => {

        // reset errors
        if (isSubmitted === true) {
            setSubmitted(false);
        }
        if (errors) {
            setErrors(removeValue(errors, key, identifier));
        }

        setValues(returnValues(values, key, value, identifier));
    }

    const setInputErrors = (key, value, identifier = null) => {

        // add or remove error
        let newErrors = errors;
        if (value !== undefined && value !== null) {
            newErrors = returnValues(errors, key, value, identifier);
        }
        else {
            newErrors = removeValue(errors, key, identifier);
        }
        setErrors(newErrors);
    }

    const contentForm = () => {
        return (
            <FormFields
                formFields={formFields}
                setValues={setInputValues}
                setErrors={setInputErrors}
                formValues={values}
                formErrors={errors}
                //identifier = null
                isSubmitted={isSubmitted}
            />
        )
    }

    return (<div>

        <ContentWrapper type="title" content="Ad uitleen hulpmiddel ophalen" />
        <ContentWrapper type='outline-box' content={contentForm()} />

        {dossierList.length > 0 &&
            <ContentWrapper 
                type="title-button" 
                content="Aangevinkte hulpmiddelen zijn ingeleverd" 
                handleFunction={handleSelect} 
                params={{ label: "Bevestigen" }} 
            />
        }

        {/** @eric: dossiers is geen object.. dus waarom Object.keys gebruiken?  */}
        {Object.keys(dossiers).length > 0 &&
            <List
                columns={columnsReturn}
                data={dossiers}
                identifier="return"
                //listToCheck={dossierList}
                handleAction={handleChecklist}
            />
        }
    </div>
    );
}