import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { AppInspectionsActionsAddarticle } from "../../../app/Inspections/Actions/Addarticle";
import { PageTopbar } from "../../../components/Page/Topbar";
import { PageHeader } from "../../../components/Page/Header";

export function RoutesInspectionsActionsAddarticle({ title, parents, current }) {

    const location = useLocation();
    const [message] = useState(location.state !== null ? location.state.message : null);
    const [messageType] = useState(location.state != null ? location.state.type : null);

    return (
        <div>

            <PageTopbar
                parents={parents}
                current={current}
            />

            <PageHeader 
                title={title}
                parents={parents}
                current={current}
                message={message}
                messageType={messageType}
                tabGroup="inspections"
            />

            <AppInspectionsActionsAddarticle
                parents={parents}
                current={current}
            />
        </div>
    );
}