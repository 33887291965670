import React from "react";
import { ValidateResponse } from "../helpers/global";
import { processAddossiersChange } from "../helpers/addossiers";
import { useParams, useNavigate } from "react-router-dom";
import { AppAddossiersSelectChangedossiers } from "./Select/Changedossiers";


export function AppAddossiersChange({ setGlobalMessage }) {

    const { userId } = useParams();
    const navigate = useNavigate();

    const processSelected = (values) => {

        if (values.products.length > 0 && values.warehouseId > 0) {

            let formData = {
                'userId': userId,
                'products': values.products,
                'warehouseId': values.warehouseId
            }

            processAddossiersChange(formData).then((res) => {

                const response = ValidateResponse(res.data, 'response');

                if (response.status === 'error') {
                    setGlobalMessage(response.message, response.status);
                } else {
                    // navigate('/addossiers/details/' + userId, {
                    //     replace: true,
                    //     state: { message: response.message, type: response.status }
                    // });
                    response.response.message = response.message;
                    response.response.status = response.status;

                    navigate('/addossiers/details/' + userId, {
                        replace: true,
                        state: { 'result': response.response }
                    });
                }
            }).catch((error) => {
                let response = ValidateResponse(error);
                setGlobalMessage(response.message, 'error');
            })
        }
        else {
            setGlobalMessage('Selecteer hulpmiddelen en magazijn voor inname', 'error');
        }
    }

    return (
        <AppAddossiersSelectChangedossiers
            userId={userId}
            setGlobalMessage={setGlobalMessage}
            processSelect={processSelected}
        />
    );

}