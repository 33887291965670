import axios from 'axios';
import { API_URL, getApiHeaders } from "./api.js"

// POST
const fetchProducts = formData => axios.post(
    `${API_URL}/admin/products/overview`, formData, getApiHeaders()
);
const fetchProductsDetails = formData => axios.post(
    `${API_URL}/admin/products/details`, formData, getApiHeaders()
);
const findQrCode = formData => axios.post(
    `${API_URL}/admin/products/findqrcode`, formData, getApiHeaders()
);
// dubbele functie! gebruik helpers/inspections -> fetchInspections
// const fetchProductInspections = postData => axios.post(
//     `${API_URL}/admin/products/inspections`, postData, getApiHeaders()
// );

// PUT/PATCH
const addQrCode = formData => axios.put(
    `${API_URL}/admin/products/add/qrcode`, formData, getApiHeaders()
);
const addPhoto = formData => axios.put(
    `${API_URL}/admin/products/add/photo`, formData, getApiHeaders()
);

export {
    fetchProducts,
    fetchProductsDetails,
    // fetchProductInspections,
    // fetchImages, // @see global
    findQrCode,
    addQrCode,
    addPhoto
}


