// import React, { useState } from "react";console
import { Link } from "react-router-dom";
// import { AppTransportDetailsArticles } from "./Articles";

export function AppTransportDetailsArticles({ stop, editMode = "disabled"}) 
{
    let stopColumns = [];
    let stopData = {};
    if (typeof (stop.info) == 'object' && Object.keys(stop.info).length > 0) {
        Object.keys(stop.info).forEach((infoKey) => {
            if (stop.info[infoKey].label !== undefined && stop.info[infoKey].value !== undefined) {
                stopColumns.push({ key: infoKey, label: stop.info[infoKey].label, type: "text" });
                stopData[infoKey] = stop.info[infoKey].value;
            }
        });
    }

   // let viewStatus = stop.status == "COMPLETED" || editMode !== "ACTIVE" ? 'disabled' : 'active';
    let toProcess = [];
    let titles = [];

    if (typeof stop.productIN === 'object' && stop.productIN.length > 0) {
        toProcess.push(stop.productIN);
        titles.push((stop.status !== 'COMPLETED' ? "Inladen" : "Ingeladen"));
    }
    if (typeof stop.productOUT === 'object' && stop.productOUT.length > 0) {
        toProcess.push(stop.productOUT);
        titles.push((stop.status !== 'COMPLETED' ? "Uitladen" : "Uitgeladen"));
    }
    if (typeof stop.productESCALATED === 'object' && stop.productESCALATED.length > 0) {
        toProcess.push(stop.productESCALATED);
        titles.push(("Geëscaleerd"));
    }

    const renderInput = (articles) => {
        return <>
            {articles.map((request) => (
                <div className="request-item" key={"transport-request-" + request.transportRequestId}>
                    <div className="d-flex flex-column item-header">
                        {request.originReference ?? ''}
                    </div>
                    <div className="d-flex flex-column item-value">
                        {request.amount}x {request.displayName}
                    </div>
                </div>
            ))}
        </>
    }

    return (
        
            <div className={"requests-list requests-list--" + (editMode === 'edit' ? 'active' : 'disabled')}>
                {toProcess.map((articles, i) => (
                    <div key={"articles-list-" + i}>
                        {editMode === 'edit' &&
                            <Link to={"../active/process/" + stop.stopId}  >
                                <div className="details-link details-link--arrow item-title">
                                    {titles[i]}
                                </div>
                                {renderInput(articles)}
                            </Link>
                        }
                        {editMode !== "edit" &&
                            <>
                                <div className="item-title">
                                    {titles[i]}
                                </div>
                                {renderInput(articles, titles[i])}
                            </>
                        }
                    </div>
                ))}
            </div>
        
    )
}