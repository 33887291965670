import React, { useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { returnValues, removeValue, validateData } from "../../../components/Form/Formfunctions";
import { FormFields } from "../../../components/Form/Fields";
import { ContentWrapper } from "../../../components/Content/Wrapper";
import { ValidateResponse } from "../../helpers/global"
import { addPhoto } from "../../helpers/articles"
/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function AppProductsDetailsAddphoto({ current, returnPath, setGlobalMessage }) {
    const { productId } = useParams();
    const location = useLocation();
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState();
    const [isSubmitted, setSubmitted] = useState(false);
    const navigate = useNavigate();

    let formFields = {
        description:{
            type:"text",
            label:"Omschrijving",
            value: values['description']
        },
        file: {
            type: "file",
            label: 'Bestand',
            value: values['file'],
        }
    }

    const setInputValues = (key, value, identifier = null) => {

        // reset errors
        if (isSubmitted === true) {
            setSubmitted(false);
        }
        if (errors) {
            setErrors(removeValue(errors, key, identifier));
        }

        setValues(returnValues(values, key, value, identifier));
    }

    const setInputErrors = (key, value, identifier = null) => {

        // add or remove error
        let newErrors = errors;
        if (value !== undefined && value !== null) {
            newErrors = returnValues(errors, key, value, identifier);
        }
        else {
            newErrors = removeValue(errors, key, identifier);
        }
        setErrors(newErrors);
    }

    const onSubmit = () => {
        setSubmitted(true);
        let validation = validateData(formFields, values, true);
        let data = values;
        if (validation.status !== 'success') {
            setErrors(validation.errors);
            let globalMessage = validation.errors.globalMessage ?? 'Het formulier kan niet verzonden worden';
            setGlobalMessage(globalMessage, 'error');
            return;
        }

        if(typeof data === 'object' && Object.keys(data).length === 0){
            let globalMessage = 'Kies een bestand';
            setGlobalMessage(globalMessage, 'error');
            return;  
        }

        const formData = {
            'file': data.file,
            'productId': productId,
            'description': data.description
        }

        addPhoto(formData).then((res) => {

            const response = ValidateResponse(res.data);

            if (response.status === 'error') {
                setGlobalMessage(response.message, response.status);
            } else {
                navigate(returnPath+'/photos/' + productId, {
                    replace: true,
                    state: { 'message': response.message, 'type': response.status }
                });
            }
        }).catch((error) => {
            let response = ValidateResponse(error);
            setGlobalMessage(response.message, response.status);
        })
    }

    const content = () => {
        return (<div className="text-component">
            <FormFields
                formFields={formFields}
                setValues={setInputValues}
                setErrors={setInputErrors}
                formValues={values}
                formErrors={errors}
                //identifier = null
                isSubmitted={isSubmitted}
            />

            <div className="form__row form__row--buttons">
                <div className="form__component">
                    <div className="form__element">
                        <button
                            className={"button button--primary"}
                            onClick={() => { onSubmit() }}
                        > Opslaan </button>
                    </div>
                </div>
            </div>
        </div>

        )
    }

    return (

        <>
            <ContentWrapper type='outline-box' content={content()} />
        </>
    )
}