import React, { useState } from "react";
import { useParams } from "react-router-dom";
//import { Identity } from "../../../../components/Identity";
import { PageTopbar } from "../../../../components/Page/Topbar";
import { PageHeader } from "../../../../components/Page/Header";
import { AppScan } from "../../../../app/Scan";
import { getPageTitle } from "../../../../app/helpers/global";
export function RoutesProductsDetailsActionsScan({ parents, current }) {

    let identifier = (parents+current).replaceAll('/', '-');
    // let group = parents.indexOf('articles/depot')===-1 ? 'location' : 'depot';

    const { productId } = useParams();
    const [title, setTitle] = useState(null);

    if (title === null || title === undefined) {
        getPageTitle('product', productId, setTitle);
    }

    return (
        <>
            <PageTopbar
                parents={parents}
                current={current}
            />
            
            {/* <Identity /> */}

            <PageHeader
                title={title}
                parents={parents}
                current={current}
                tabGroup={"details"+parents.split('/')[1]}
                variables={{productId:productId}}
                identifier={identifier}
            />
 
            <AppScan
                productId={productId}
                method="add"
                title="QR code koppelen"
                returnPath={"/" + parents + "/" + productId}
                forward={"/" + parents.replace('/actions', '/details') + "/" + productId}
            />
        </>
    );
}