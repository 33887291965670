import React, { useEffect, useState } from "react";
import { ValidateResponse, getCurrentPage } from "../helpers/global";
import { fetchProductsDetails } from "../helpers/articles";
import { useParams, useLocation } from "react-router-dom";
import { Details } from "../../components/Details"
import { Message } from "../../components/Message";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function AppProductsDetails({ current, section, setGlobalMessage }) {

    const { productId } = useParams();
    const location = useLocation();
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState();
    let allColums = [];

    useEffect(() => {
        const callData = {
            section : section,
            productId: productId
        }

        fetchProductsDetails(callData).then((res) => {
            const response = ValidateResponse(res.data, 'details');
            if (response.status === 'error') {
                setGlobalMessage(response.message, response.status);
            }
            else {
                const data = res.data;
                setData(data.details);
            }
            setIsLoaded(true);

        }).catch((error) => {
            const response = ValidateResponse(error, 'products');
            setGlobalMessage(response.message, response.status);
        })

    }, [productId])

    if (isLoaded === false) {
        return (<Message type="loading" />);
    }

    if (data === undefined || data === null || Object.keys(data).length === 0) {
        return (<Message type="noData" />);
    }

         allColums = [
            // west
            { key: "articleName", label: "Artikelnaam", type: "text", gcId:"west"},
            { key: "statusDisplay", label: "Status", type: "text",gcId:"west" },
            { key: "serialNumber", label: "Serienummer", type: "text", gcId:"west"},
            { key: "frameNumber", label: "Framenummer", type: "text", gcId:"west"},
            { key: "barcode", label: "Qr code", type: "text", gcId:"west"},
        ];

    // Set/remove status-specific fields
    let columns = [];

    allColums.forEach((row) => {
                columns.push(row);
    });
    if(section === 'extern'){
    columns.push({ key: "warehouse", label: "Magzijn", type: "text", gcId:"west"});
    columns.push({ key: "owner", label: "Producteigenaar", type: "text", gcId:"west"});
    }

    if(data.statusReference !== undefined && data.StatusReference !== null){
    columns.push({ key: "statusReference", label: "Status referentie", type: "text", gcId:"west"});
    }
    if(section === 'intern' && data.departmentRoom !== undefined && data.departmentRoom !== null && data.departmentRoom !== ""){
    columns.push({ key: "departmentRoom", label: "Afdeling/kamernr.", type: "text", gcId:"west"});
    }

    if(section === 'extern' && data.startDate !== undefined && data.startDate !== null && data.startDate !== ""){
        columns.push({ key: "startDate", label: "Start depot", type: "text", gcId:"west"});
        }

    columns.push({ key: "productBrand", label: "Merk", type: "text", gcId:"east"});
    columns.push({ key: "productType", label: "Type", type: "text", gcId:"east"});

    // Divide in to left/right
    if (data.customFields !== undefined && Object.keys(data.customFields).length > 0) {
        Object.keys(data.customFields).forEach((item) => {
            columns.push({ label: data.customFields[item].label, value:data.customFields[item].value, type: "text", gcId:"east"});
        });
    }

    if (data.dayPrice !== undefined && data.dayPrice !== null) {
        columns.push({ key: "dayPrice", label: "Dagwaarde", type: "text", gcId:"east"});
    }

    // and the comments
    columns.push({ key: "comments", label: "Opmerkingen", type: "text", gcId:"east"});

    // Reservation message
    let reservationMessage;
    if(data.enableReservation === 1) {
        reservationMessage = data.reservationReason;
    }

    return (
        <>
            <Message message={reservationMessage} title={"Reservering"}/>
            <Details columns={columns} data={data} />
        </>
        
    )
}