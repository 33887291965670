import axios from 'axios';
import { API_URL, getApiHeaders } from "./api.js"

// GET
const fetchWarehouses = () => axios.get(
    `${API_URL}/admin/addossiers/warehouses`, getApiHeaders()
);
const fetchCustomer = id => axios.get(
    `${API_URL}/admin/addossiers/customer/details/` + id, getApiHeaders()
);

//POST
const fetchCustomers = formData => axios.post(
    `${API_URL}/admin/addossiers/customer/overview`, formData, getApiHeaders()
);
const getRentableSerialnumberList = formData => axios.post(
    `${API_URL}/admin/addossiers/serialnumber/overview`, formData, getApiHeaders()
);
const fetchAddossiersCustomer = formData => axios.post(
    `${API_URL}/admin/addossiers/customers/dossiers`, formData, getApiHeaders()
);
const getRentableSerialnumber = formData => axios.post(
    `${API_URL}/admin/addossiers/serialnumber/getvalidated`, formData, getApiHeaders()
);
// PUT/PATCH
const processAddossierDelivery = formData => axios.put(
    `${API_URL}/admin/addossiers/process/delivery`, formData, getApiHeaders()
);
const processAddossiersReturn = formData => axios.put(
    `${API_URL}/admin/addossiers/process/return`, formData, getApiHeaders()
);
const processAddossiersChange = formData => axios.put(
    `${API_URL}/admin/addossiers/process/change`, formData, getApiHeaders()
);

export {
    //GET
    fetchWarehouses,
    fetchCustomer, //without s

    //POST
    fetchCustomers, // with s
    getRentableSerialnumberList, // ??? wat is het verschil met fetchAddossiersCustomer
    fetchAddossiersCustomer,// ??? wat is het verschil met fetchAddossiers ?
    getRentableSerialnumber, // een enkel addossier ophalen ??? waarom? bedoel je misschien een serienummer? dat is iets heel anders dan een addossier!
    processAddossierDelivery,
    processAddossiersReturn,
    processAddossiersChange
}