import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ValidateResponse, setCurrentPage, getCurrentPage } from "../../helpers/global";
// import { fetchProductInspections } from "../../helpers/articles";
import { fetchInspections } from "../../helpers/inspections";
import { List } from "../../../components/List";
import { Message } from "../../../components/Message";
import { ListPagination } from "../../../components/List/Pagination";
import { ContentWrapper } from "../../../components/Content/Wrapper";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function AppProductsDetailsInspections({ productId, current, parents, setFilterFields, filterValues, identifier }) {

    let pageSize = 10; // default values ergens globaal opslaan?

    const navigate = useNavigate();
    const [inspections, setInspections] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [loadFilters, setLoadFilters] = useState(true);
    const [pageNr, setPageNr] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [error, setError] = useState();
    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem("filters" + identifier)));
    const [filterString, setFilterString] = useState();
    const [isAllowedCreateInspection, setIsAllowedCreateInspection] = useState(false);

    if (JSON.stringify(filterValues) !== filterString) {
        setFilterString(JSON.stringify(filterValues));
        setIsLoaded(false);
        setFilters(filterValues);
        setCurrentPage(1, current, setPageNr);
    }

    if (pageNr !== getCurrentPage(current)) {
        setPageNr(getCurrentPage(current));
    }

    const handlePagination = (page) => {
        setCurrentPage(page, current, setPageNr);
    }

    let listColumns = [
        { key: "statusLabel", label: "Status", type: "text" },
        { key: "typeLabel", label: "Type keuring", type: "text" },
        { key: "plannedDate", label: "Te keuren voor", type: "date" },
        { key: "inspectDate", label: "Keuringsdatum", type: "date" },
        { key: "inspectionNumber", label: "Keuringsrapport", type: "download", display: "icon", fileName: "documentName", documentType: "inspection" }
    ];

    // Afgevoerd zie je niet... wel afgerond
    const optionsConclusion = [
        { value: "PENDING", label: "Te keuren" }, // new/processing/waiting
        { value: "COMPLETED", label: "Goedgekeurd" }, // new/processing/waiting
        { value: "REJECTED", label: "Afgekeurd" }, // new/processing/waiting
    ]

    // // The filters are set here and send to parent onloaded
    let filterFields = {
        'conclusion': { type: 'select', label: "Status", options: optionsConclusion },
    }

    useEffect(() => {

        const callData = {

            filters: {
                productId: productId,
                context: 'product',
                limitOffset: pageSize * pageNr - (pageSize - 1),
                limitRows: pageSize,
                ...filters
            }
        }

        fetchInspections(callData).then((res) => {

            const response = ValidateResponse(res.data, 'inspections');

            if (response.status === 'error') {
                setError(response.message, response.status);
            }
            else {
                if (response.totalCount !== undefined) {
                    setTotalCount(response.totalCount);
                    setIsAllowedCreateInspection(response.isAllowedCreateInspection);

                    if (loadFilters !== current) {
                        setFilterFields(filterFields);
                        setLoadFilters(current);
                    }

                }
                response.inspections.forEach((inspection) => {
                    // if(inspection.hasForm == 1){
                    //     inspection.link = <Link to={`/inspections/process/${inspection.inspectionId}`}>{'Link'}</Link>
                    // }
                })
                setInspections(response.inspections);
                setIsLoaded(true);
            }
        }).catch((error) => {
            const response = ValidateResponse(error);
            setError(response.message);
        });

    }, [productId, filters, setFilterFields, pageNr, pageSize]);

    const showCreateInspectionButton = () => {
        //content, type, className = "", handleFunction, params
        let params = {buttons:[]};
        let content = false;

        if (isAllowedCreateInspection === true) {
            params.buttons.push({
                label: "Keuring aanmaken",
                value: productId
            });
            content = true;
        }

        if(content === true && params.buttons.length > 0) {
            return <ContentWrapper 
                type = "buttons" 
                content = {content}
                params = {params}
                handleFunction = {handleCreateInspectionButton}
            />
        }
        else {
            return <></>
        }

    }
    const handleCreateInspectionButton = (event) => {
        let path = "/"+parents+"/inspections/create/" + event.target.getAttribute('data-value');
       navigate(path);
    }

    if (error !== undefined) {
        return (<Message type="error" message={error} />);
    }
    if (isLoaded === false) {
        return (<Message type="loading" />);
    }
    if (inspections === undefined || inspections === null || Object.keys(inspections).length === 0) {
        return (<>{showCreateInspectionButton()}<Message type="noData" /></>);
    }

    const showButton = () => {
        //content, type, className = "", handleFunction, params
        let params = { buttons: [] };
        let content = false;
        inspections.forEach((item) => {
            if (item.status === 'PENDING' || item.status === 'REJECTED') {
                params.buttons.push({
                    label: "Keuring verwerken: " + item.typeLabel,
                    value: item.inspectionId
                });
                content = true;
            }
        });

        if (content === true && params.buttons.length > 0) {
            return <ContentWrapper
                type="buttons"
                content={content}
                params={params}
                handleFunction={handleButton}
            />
        }
        else {
            return <></>
        }

    }
    const handleButton = (event) => {
        let path = "/inspections/process/" + event.target.getAttribute('data-value');
        navigate(path);
    }

    return (
        <>
            {showCreateInspectionButton()}
            {showButton()}

            <List
                columns={listColumns}
                data={inspections}
                identifier={identifier}
            />

            <div className={'spacer'}></div>

            <ListPagination
                currentPage={pageNr}
                totalCount={totalCount}
                pageSize={pageSize}
                setCurrentPage={handlePagination}
            />
            <div style={{ height: '30px' }}></div>
        </>
    );
}