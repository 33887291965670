import React, { useState} from "react";
import { useLocation } from "react-router-dom";
////import { Identity } from "../../components/Identity";
import { PageHeader } from "../../components/Page/Header";
import { PageTopbar } from "../../components/Page/Topbar";
import { AppAddossiersSearchcustomer } from "../../app/Addossiers/Searchcustomer";

export function RoutesAddossiersSearchcustomer({title, parents, current}) {

	const location = useLocation();
	const [message, setMessage] = useState(location.state !== null ? location.state.message : null);
	const [messageType, setMessageType] = useState(location.state != null ? location.state.type : null);

	const setGlobalMessage = (message, type) => {
		setMessage(message);
		setMessageType(type);
	} 

	return (
        <div>
            {/* <Identity /> */}
			<PageTopbar 
                parents={parents}
                current={current}
            />
            <PageHeader title={title} parents={parents} current={current} message={message} messageType={messageType}/>
            <AppAddossiersSearchcustomer current={current} setGlobalMessage={setGlobalMessage} />
        </div>
    );

}