import React from "react";
import { PageTopbar } from "../components/Page/Topbar";
import { AppScan } from "../app/Scan";

export function RoutesScan({ parents, current }) {

    return (
        <>
            <PageTopbar
                parents={parents}
                current={current}
            />
            <AppScan
                method="search"
                returnPath={"/"}
            />
        </>
    );
}