import React, { useState } from "react";
import { PageTopbar } from "../components/Page/Topbar";
import { Message } from "../components/Message";
import { useLocation } from "react-router-dom";
//import { Identity } from "../components/Identity";

export function RoutesError({ parents = "/", current }) {

    const location = useLocation();
    const {message} = useState((location.state !== null 
        ? location.state.message 
        : null));
    const {messageType} = useState((location.state !== null 
        ? location.state.type 
        : null));

    return (
        <div>
            {/* <Identity /> */}
            <PageTopbar 
                parents={parents}
                current={current}
            />
            <Message type={messageType} message={message} />
        </div>
    );
}