import React, { useState} from "react";
import { useParams, useLocation } from "react-router-dom";
////import { Identity } from "../../components/Identity";
import { AppAddossiersDeliver } from "../../app/Addossiers/Deliver";
import { PageHeader } from "../../components/Page/Header";
import { PageTopbar } from "../../components/Page/Topbar";

export function RoutesAddossiersDeliver({title, parents, current}) {
	const {userId} = useParams();

const location = useLocation();
const [message, setMessage] = useState(location.state !== null ? location.state.message : null);
const [messageType, setMessageType] = useState(location.state != null ? location.state.type : null);

const setGlobalMessage = (message, type) => {
	setMessage(message);
	setMessageType(type);
} 

return (
	<div>
		{/* <Identity /> */}
		<PageTopbar 
                parents={parents}
                current={current}
            />
		<PageHeader title={title} parents={parents} current={current} tabGroup="addossiers" message={message} messageType={messageType} variables={{userId: userId}}/>
		<AppAddossiersDeliver userId={userId} current={current} setGlobalMessage={setGlobalMessage} />
	</div>
);
}