import React, { useState } from "react";
import { useParams } from "react-router-dom";
//import { Identity } from "../../../components/Identity";
import { PageTopbar } from "../../../components/Page/Topbar";
import { PageHeader } from "../../../components/Page/Header";
import { AppProductsDetailsInspections } from "../../../app/Products/Details/Inspections";
import { getPageTitle } from "../../../app/helpers/global";

export function RoutesProductsDetailsInspections({ parents, current }) {

    let identifier = (parents + current).replaceAll('/', '-');
    const { productId } = useParams();
    const [filterFields, setFilterFields] = useState([]);
    const [filterValues, setFilterValues] = useState(JSON.parse(localStorage.getItem("filters" + identifier)) ?? {});
    const [refresh, setRefresh] = useState(false);
    const [title, setTitle] = useState(null);

    if (title === null) {
        getPageTitle('product', productId, setTitle);
    }

    const resetFilterValues = (values) => {
        setFilterValues(values);
        setRefresh(refresh === false);
    }
    
    return (
        <div>

            <PageTopbar
                parents={parents}
                current={current}
            />

            {/* <Identity /> */}

            <PageHeader
                title={title}
                tabGroup={"details" + parents.split('/')[1]}
                variables={{ productId: productId }}
                parents={parents}
                current={current}
                filterFields={filterFields}
                setFilterValues={resetFilterValues}
                identifier={identifier}
            />

            <AppProductsDetailsInspections
                productId={productId}
                current={current}
                parents={parents}
                filterValues={filterValues}
                setFilterFields={setFilterFields}
                identifier={identifier}
            />
        </div>
    );
}