import React, { useEffect, useState } from "react";
import { ValidateResponse, getCurrentPage, setCurrentPage } from "../helpers/global";
import { fetchInspections } from "../helpers/inspections";
import { useLocation } from "react-router-dom";
import { ListPagination } from "../../components/List/Pagination";
import { Message } from "../../components/Message";
import { List } from "../../components/List";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function AppInspectionOverview({ current, identifier, setFilterFields, filterValues, setGlobalMessage, inspectionType }) {

    let pageSize = 10; // default values ergens globaal opslaan?
    const location = useLocation();
    const [isLoaded, setIsLoaded] = useState(false);
    const [pageNr, setPageNr] = useState(getCurrentPage(current));
    const [totalCount, setTotalCount] = useState(0);
    const [filterString, setFilterString] = useState("{}");
    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem('filters' + identifier)));
    const [data, setData] = useState();


    useEffect(() => {
        if (location.state !== null && location.state.currentPage !== undefined) {
            setCurrentPage(1, current, setPageNr);
        }
    }, [location.state]);

    if (JSON.stringify(filterValues) !== filterString) {
        setFilterString(JSON.stringify(filterValues));
        setIsLoaded(false);
        setFilters(filterValues);
        setCurrentPage(1, current, setPageNr);
    }

    let storedPageNr = getCurrentPage(current);
    if (pageNr !== storedPageNr) {
        setPageNr(storedPageNr);
    }

    const handlePagination = (page) => {
        setCurrentPage(page, current, setPageNr);
    }

    // Default list columns
    let listColumns = [
        { key: "article", type: "title", hideView: "large" },
        { key: "article", label: "Artikelnaam", type: "text", hideView: "small" },
        { key: "serialNumber", label: "Serienummer", type: "text" },
        { key: "typeLabel", label: "Type keuring", type: "text" },
        { key: "statusLabel", label: "Status", type: "text" },
        { key: "owner", label: "Product eigenaar", type: "text" },
    ];

    // Set specific columns for archive or active
    if (current === 'archive') {
        delete (listColumns[3]);
        listColumns.push({ key: "inspectDate", label: "Keuringsdatum", type: "date" })
        listColumns.push({ key: "inspectionNumber", label: "Keuringsrapport", type: "download", display: "icon", fileName: "documentName", documentType: "inspection" })
    } else {
        listColumns.push({ key: "plannedDate", label: "Keuren voor", type: "date" })
    }

    // Add the processing link for active inspections with online form
    if (current === 'overview') {
        let link = "/inspections/process/:inspectionId";

        listColumns[0] = { key: "article", type: "link", path: link, id: "inspectionId", hideView: "large" };
        listColumns.push({ key: "inspectionId", type: "link", path: link, id: "inspectionId", hideView: "small" })
    }

    const defineFilters = () => {
        let filterFields = {}
        filterFields = {
            'serialNumber': { type: 'text', 'label': "Serienummer" },
            'ownerEntityId': { type: 'location', options: {locationId:"locationId", parentId:0 } },
            'plannedDate': { type: 'date', 'label': "Keuren voor" },
        }
        filterFields['inspectionType'] = { type: 'inspectionType' }
        setFilterFields(filterFields);
    }

    useEffect(() => {

        if (inspectionType === 'tilband') {
            if (filters == null) {
                setFilters({ inspectionType: 'TILBAND' })
            } else {
                filters['inspectionType'] = 'TILBAND';
            }
        }

        if (inspectionType === 'acculader') {
            if (filters == null) {
                setFilters({ inspectionType: 'ACCULADER' })
            } else {
                filters['inspectionType'] = 'ACCULADER';
            }
        }

        const callData = {
            filters: {
                context: current,
                limitOffset: pageSize * pageNr - (pageSize - 1),
                limitRows: pageSize,
                ...filters
            }
        }


        fetchInspections(callData).then((res) => {
            const response = ValidateResponse(res.data, 'inspections');
            if (response.status === 'error') {
                setGlobalMessage(response.message, response.status);
            }
            else {
                setData(response.inspections);
                setTotalCount(response.totalCount)
            }
            setIsLoaded(true);
            defineFilters(); // activate filters if data is loaded
        }).catch((error) => {
            const response = ValidateResponse(error, 'status');
            setGlobalMessage(response.message, response.status);
        })
    }, [filters, setData, pageNr, setTotalCount, setIsLoaded, setData, isLoaded, current, pageSize])

    if (isLoaded === false) {
        return (<Message type="loading" />);
    }
    if (isLoaded === true && (data === undefined || data === null || Object.keys(data).length === 0)) {
        return (<Message type="noData" />);
    }

    return (
        <>
            <List
                columns={listColumns}
                data={data}
            />
            <ListPagination
                className="pagination-bar"
                currentPage={pageNr}
                totalCount={totalCount}
                pageSize={pageSize}
                setCurrentPage={handlePagination}
            />
        </>)
}