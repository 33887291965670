import React, { useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { AppProductsDetailsActions } from "../../../app/Products/Details/Actions";
import { PageHeader } from "../../../components/Page/Header";
import { PageTopbar } from "../../../components/Page/Topbar";
import { getPageTitle } from "../../../app/helpers/global";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function RoutesProductsDetailsActions({ parents, current }) {
    const { productId } = useParams();
    const location = useLocation();
    const [message, setMessage] = useState(location.state !== null ? location.state.message : null);
    const [messageType, setMessageType] = useState(location.state != null ? location.state.type : null);
    const setGlobalMessage = (message, type) => {
        setMessage(message);
        setMessageType(type);
    }
    const [title, setTitle] = useState(null);
    const section = parents.indexOf('/intern') === -1 ? 'extern' : 'intern';

    if (title === null || title === undefined) {
        getPageTitle('product', productId, setTitle);
    }

    return (
        <div>
            <PageTopbar
                parents={parents}
                current={current}
            />
            <PageHeader title={title}
                parents={parents}
                current={current}
                tabGroup={"details" + parents.split('/')[1]}
                message={message}
                messageType={messageType}
                variables={{ productId: productId }} />

            <AppProductsDetailsActions
                section={section}
                current={current}
                redirectPath={"/" + parents}
                setGlobalMessage={setGlobalMessage} />
        </div>
    )
}