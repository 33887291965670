import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Identity } from "../../components/Identity";
import { AppInspectionsProcess } from "../../app/Inspections/Process";
import { PageHeader } from "../../components/Page/Header";
import { PageTopbar } from "../../components/Page/Topbar";
import { getPageTitle } from "../../app/helpers/global";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function RoutesInspectionsProcess({ parents, current }) {
    const location = useLocation();
    let { inspectionId } = useParams();
    const [message, setMessage] = useState(location.state !== null ? location.state.message : null);
    const [messageType, setMessageType] = useState(location.state != null ? location.state.type : null);
    const setGlobalMessage = (message, type) => {
        setMessage(message);
        setMessageType(type);
    }
    const [title, setTitle] = useState(null);

    if (title === null || title === undefined) {
        getPageTitle('inspection', inspectionId, setTitle);
    }

    return (
        <div>
            <Identity />
            <PageTopbar
                parents={parents}
                current={current}
            />
            <PageHeader title={title}
                parents={parents}
                current={current}
                message={message}
                messageType={messageType}
            />

            <AppInspectionsProcess
                current={current}
                setGlobalMessage={setGlobalMessage}
                inspectionId={inspectionId}
                returnPath={"/" + parents}
            />
        </div>
    )
}