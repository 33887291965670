import React, { useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { PageHeader } from "../../components/Page/Header";
import { PageTopbar } from "../../components/Page/Topbar";
////import { Identity } from "../../components/Identity";
import { AppTransportProcess } from "../../app/Transport/Process";

export function RoutesTransportProcess({title, parents, current}) {

    let { stopId } = useParams();
    const location = useLocation();
    const [ message, setMessage] = useState(location.state !== null ? location.state.message : null);
    const [ messageType, setMessageType] = useState(location.state != null ? location.state.type : null);
    const [ pageTitle, setPageTitle ] = useState(title);

    const setGlobalMessage = (message, type) => {
        setMessage(message);
        setMessageType(type);
    }

    return (
        <div>
            {/* <Identity /> */}
            <PageTopbar 
                parents={parents}
                current={current}
            />
            <PageHeader title={pageTitle} parents={parents} current={current} message={message} messageType={messageType} />
            <AppTransportProcess stopId={stopId} setPageTitle={setPageTitle} setGlobalMessage={setGlobalMessage} />
        </div>
    );
}