import React, { useEffect, useState } from "react";
import { ValidateResponse } from "../../helpers/global";
import { fetchProductsDetails } from "../../helpers/articles";
import { validateRoute } from "../../helpers/auth";
import { Link, useParams} from "react-router-dom";
import { ContentWrapper } from "../../../components/Content/Wrapper";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function AppProductsDetailsActions({current, section, setGlobalMessage, redirectPath}) {
    const { productId } = useParams();
    const [data, setData] = useState();
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const callData = {
            section : section,
            productId: productId
        }

        fetchProductsDetails(callData).then((res) => {
            const response = ValidateResponse(res.data, 'details');
            if (response.status === 'error') {
                setGlobalMessage(response.message, response.status);
            }
            else {
                const data = res.data;
                setData(data.details);
            }
            setIsLoaded(true);

        }).catch((error) => {
            const response = ValidateResponse(error, 'products');
            setGlobalMessage(response.message, response.status);
        })

    }, [productId]);

    if (isLoaded === false) {
        return <></>
    }

    const content = () => {
        return (
            <>
                {data !== undefined && data.barcode === null && validateRoute('/products', 2) === true &&
                    <div className="tiles__column">
                        <Link to={redirectPath+"/scan/" + productId} className={"tile tile__scannen active"}>
                            <span className="icon"></span>
                            <span className="tile__title">
                                Qr code<br />koppelen
                            </span>
                        </Link>
                    </div>
                }
                <div className="tiles__column">
                    <Link to={redirectPath+"/addphoto/" + productId } className={"tile tile__photos"}>
                        <span className="icon"></span>
                        <span className="tile__title">
                            Foto<br />Toevoegen
                        </span>
                    </Link>
                </div>
            </>
        )
    }

    return (
        <ContentWrapper
            type="tiles-secondary"
            content={content()}
        />
    );
}