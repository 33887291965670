
/**
 * add roles to route or add routes to role ?
 * @todo: nu staan alle routes in "customer" maar deze kunnen opgesplitst worden per segment 
 * op die manier zijn ze gemakkelijker onder te verdelen in verschillende rollen
 * mensen kunnen meerdere rollen hebben ... dus routes kunnen dubbel geladen worden, rekening mee houden
//  */
import { routes as routesAuth } from "./auth"
import { routes as routesIntern } from "./intern"

export const getRoutesConfig = () => {

    return {
        // routes available without authentication
        public: [ routesAuth ],
    
        // routes that need authentication
        protected: [ routesIntern ],
    };
}
