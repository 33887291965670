import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getFormFieldsInspection, processInspection } from "../helpers/inspections";
import { FormFields } from "../../components/Form/Fields";
import { FormInputSubmit } from "../../components/Form/Input/Submit";
import { returnValues, removeValue, validateData } from "../../components/Form/Formfunctions";
import { ContentWrapper } from "../../components/Content/Wrapper";
import { ValidateResponse } from "../helpers/global";


export function AppInspectionsProcess({
    setGlobalMessage,
    inspectionId,
    returnPath
}) {

    const navigate = useNavigate();
    const [formFields, setFormFields] = useState();
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState();
    const [reload, setReload] = useState(false);

    function getDate() {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        return `${year}-${month}-${date}`;
    }

    useEffect(() => {
        getFormFieldsInspection(inspectionId).then((res) => {
            const response = ValidateResponse(res.data, 'inspection');

            if (response.status === 'error') {
                setGlobalMessage(response.message, response.status);
            }
            else {

                let formValues = values;

                response.inspection.confirm = {
                    type: "radio",
                    label: "Keuring uitgevoerd *",
                    value: formValues.confirm,
                    rules: ["required"],
                    options: [{ label: 'Goedgekeurd', value: '1' }, { label: 'Afgekeurd', value: '0' }]
                }

                if (formValues.processDate == null) {
                    formValues.processDate = getDate();
                }

                response.inspection.processDate = {
                    type: "date",
                    label: "Verwerkingsdatum *",
                    value: formValues.processDate,
                    options: { min: "-1", max: null },
                    rules: ["required"],
                }

                let customFields = response.inspection;

                if (customFields.ownerEntityId !== undefined) {

                    console.log('EEN');
                    console.log(formValues);
                    console.log(customFields);

                    if(customFields.ownerEntityId !== undefined && customFields.ownerEntityId.value !== undefined) {
                        formValues.ownerEntityId = customFields.ownerEntityId.value ?? null;
                    }
                    if(customFields.departmentId !== undefined && customFields.departmentId.value !== undefined) {
                        formValues.departmentId = customFields.departmentId.value ?? null;
                    }
                    if(customFields.room !== undefined && customFields.room.value !== undefined) {
                        formValues.room = customFields.room.value ?? null;
                    }

                    customFields.ownerEntityId = {
                        type: "location", 
                        options: {
                            locationId: "ownerEntityId",
                            departmentId: "departmentId",
                            room: "room",
                            parentId: customFields.mainEntityId.value
                        }
                    }
                    delete (customFields.departmentId);
                    delete (customFields.room);
                }
                setFormFields(customFields);
                setValues(formValues);
            }

        }).catch((error) => {
            const response = ValidateResponse(error, 'inspection');
            setGlobalMessage(response.message, response.status);
        })

    }, []);

    const submitForm = () => {

        let validation = validateData(formFields, values, true);
        if (validation.status !== 'success') {
            setErrors(validation.errors);
            let globalMessage = validation.errors.globalMessage ?? 'Het formulier kan niet verzonden worden';
            setGlobalMessage(globalMessage, 'error');
            return;
        }

        let formData = {
            'inspectionId': inspectionId,
            'data': values,
        }

        processInspection(formData).then((res) => {
            const response = ValidateResponse(res.data);
            if (response.status === 'error') {
                setGlobalMessage(response.message, response.status);
            }
            else {
                navigate(returnPath+'/overview', {
                    replace: true,
                    state: { 'message': response.message, 'type': response.status, 'inspectionId': inspectionId, 'currentPage': 1 }
                });
            }

        }).catch((error) => {
            const response = ValidateResponse(error);
            setGlobalMessage(response.message, response.status);
        })
    }

    const setInputValues = (key, value, identifier = null) => {

        // Load form fields based on chosen values
        if (key === 'confirm') {

            let fieldsInspection = formFields;

            if (value === 0 || value === "0") {

                fieldsInspection.rejectStatus = {
                    type: "radio",
                    label: "Vervolg status",
                    value: values.rejectStatus,
                    options: [{ label: 'Reparatie', value: 'REPAIR' }, { label: 'Afvoeren', value: 'REMOVE' }]
                }

                fieldsInspection.explanation = {
                    type: "textarea",
                    label: "Uitleg/reden afkeur",
                    value: values.explanation,
                }

                delete fieldsInspection.confirmStatus;
                delete fieldsInspection.confirmRepair;
            }
            else {

                fieldsInspection.confirmStatus = {
                    type: "radio",
                    label: "Vervolgstatus",
                    value: values.confirmStatus,
                    options: [{ label: 'Product vrijgegeven', value: 'RELEASE' }, { label: 'Reparatie uitgevoerd', value: 'REPAIR' }]
                }

                delete fieldsInspection.rejectStatus;
                delete fieldsInspection.explanation;
            }

            setFormFields(fieldsInspection);
            setReload(reload === false);
        }

        if(key === 'confirmStatus') {

            let fieldsInspection = formFields;

            if(value === 'REPAIR') {

                fieldsInspection.confirmRepair = {
                    type: "textarea",
                    label: "Omschrijving Reparatie",
                    value: values.confirmRepair,
                }
            }
            else {
                delete fieldsInspection.confirmRepair;
            }
            setFormFields(fieldsInspection);
            setReload(reload === false);
        }

        if (errors) {
            setErrors(removeValue(errors, key, identifier));
        }
        setValues(returnValues(values, key, value, identifier));
    }

    const setInputErrors = (key, value, identifier = null) => {

        // add or remove error
        let newErrors = errors;
        if (value !== undefined && value !== null) {
            newErrors = returnValues(errors, key, value, identifier);
        }
        else {
            newErrors = removeValue(errors, key, identifier);
        }
        setErrors(newErrors);
    }

    const contentValidate = () => {

        return (
            <>
                <FormFields
                    formFields={formFields}
                    setValues={setInputValues}
                    setErrors={setInputErrors}
                    formValues={values}
                    formErrors={errors}
                    wrapper="wrapper"
                />

                <div className="spacer" />

                <FormInputSubmit handleSubmit={submitForm} submitLabel="Verwerken" />
            </>
        )
    }

    if (formFields !== undefined) {
        return (
            <div>
                <ContentWrapper type='outline-box' content={contentValidate()} />
            </div>
        );
    }
    else {
        return <></>
    }
}