import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { userLogin, getBaseStructure } from "../helpers/auth";
import { Message } from "../../components/Message";
import { ContentWrapper } from "../../components/Content/Wrapper";

export function AppAuthLogin() {

    const navigate = useNavigate();
    const [error, setError] = useState();
    const [passwordShown, setPasswordShown] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = values => {

        let requiredValues = ['emailaddress', 'password'];
        let validation = {};

        requiredValues.forEach((requiredField) => {
            if (values[requiredField] === undefined || values[requiredField] === null || values[requiredField].length === 0) {
                validation[requiredField] = 'Verplicht veld';
            }
        });

        if (Object.keys(validation).length !== 0) {
            return;
        }

        const formData = {
            'emailaddress': values.emailaddress,
            'password': btoa(values.password)
        }

        let generalError = 'Er is iets foutgegaan, probeer het later nog een keer of neem contact op met van der Mark Zorghulpmiddelen.'

        userLogin(formData).then((res) => {

            const response = res.data;

            if (response.status === "success" && response.auth.token !== null) {
                localStorage.setItem("auth", JSON.stringify(response.auth));
                getBaseStructure(response.auth); // set routes structure & main menu

                //props.authStatusHandler(true); wat doet die?
                
                navigate('/home');
            }
            else {
                setError(response.message ?? generalError);
            }
        }).catch((error) => {
            console.log(error);
            setError(generalError);
        });
    }

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    const content = () => {
        return (
            <div className="outline-box text-component">

                <Message type="error" message={error} />
                <h3>Inloggen</h3>

                <form className="form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="form__row">
                        <div className="form__component">
                            <label htmlFor="emailaddress" className="form__label">
                                {errors.emailaddress && <span style={{ color: 'red' }}>Verplicht veld: </span>}
                                Gebruikersnaam of e-mail
                            </label>
                            <div className="form__element">
                                <input
                                    placeholder="Je gebruikersnaam"
                                    type="text"
                                    id={"emailaddress"}
                                    className={"form-control"} {...register("emailaddress", { required: true })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__component">

                            <label htmlFor="password" className="form__label">
                                {errors.password && <span style={{ color: 'red' }}>Verplicht veld: </span>}
                                Wachtwoord
                            </label>
                            <div className="form__element form__element--password">
                                <input
                                    className={"form-control"}
                                    id={"password"}
                                    type={passwordShown ? "text" : "password"}
                                    placeholder={"Je wachtwoord"}{...register("password", { required: true })}
                                />
                                <div className="toggle-password"
                                    onClick={togglePasswordVisiblity}></div>
                            </div>
                        </div>
                    </div>

                    <div className="form__row form__row--buttons">
                        <div className="form__component">
                            <div className="form__element">
                                <button type="submit" className={"button button--primary"}>
                                    <span className={"me-1"}></span>
                                    Inloggen
                                </button>
                            </div>
                        </div>
                        <div className="form__component">
                            <div className="form__element">
                                <Link to={"/auth/forgot"} className={"button button--readmore"}>
                                    <span className={"read-more"}></span>
                                    Wachtwoord vergeten?
                                </Link>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }

    return (
        <>
            <ContentWrapper
                type="centred"
                content={content()}
                className="outline-box--form"
            />
        </>
    );
}