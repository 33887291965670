import React, { useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { PageHeader } from "../../components/Page/Header";
import { PageTopbar } from "../../components/Page/Topbar";
import { AppTransportSign } from "../../app/Transport/Sign";
////import { Identity } from "../../components/Identity";

export function RoutesTransportSign({ title, parents, current }) {

    let { stopId } = useParams();
    const location = useLocation();
    const { message } = useState((location.state !== null
        ? location.state.message
        : null));
    const { messageType } = useState((location.state !== null
        ? location.state.type
        : null));
    const [pageTitle, setPageTitle] = useState(title);

    const handlePageTitle = (newTitle) => {
        if (pageTitle !== newTitle) {
            setPageTitle(newTitle);
        }
    }

    return (
        <div>
            {/* <Identity /> */}
            <PageTopbar
                parents={parents}
                current={current}
            />
            <PageHeader title={pageTitle} parents={parents} current={current} message={message} messageType={messageType} />
            <AppTransportSign
                stopId={stopId}
                setPageTitle={handlePageTitle}
                parents={parents}
            />
        </div>
    );
}