import React, { useState, useEffect } from "react";
import { ValidateResponse } from "../helpers/global";
import { fetchStopDetails } from "../helpers/transport";
import { Message } from "../../components/Message";
import { Link, useParams, useNavigate } from "react-router-dom";
import { ContentWrapper } from "../../components/Content/Wrapper";

export function AppTransportDocuments({ parents = 'transport/active' }) {

    let { stopId } = useParams();
    const navigate = useNavigate();
    const [state, setState] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        fetchStopDetails(stopId).then((res) => {

            const response = ValidateResponse(res.data, 'stop');
            setIsLoaded(true);

            if (response.status === 'error') {
                setError(response.message);
                return;
            }
            else {
                const data = response.stop

                if (data.documents === undefined) {
                    setError("Er zijn geen documenten om te ondertekenen");
                    return;
                }

                let allSigned = true;

                Object.keys(data.documents).forEach((document) => {
                    if (data.documents[document].isSigned === false
                        && data.documents[document].type !== 'DEPOT-CASE'
                        && data.documents[document].type !== 'RENTAL-STOP') {
                        allSigned = false;
                    }
                });

                if (allSigned === true) {
                    navigate("/" + parents + "/details/" + data.transportId);
                }

                setState(data);
            }
        }).catch((error) => {
            const response = ValidateResponse(error);
            setError(response.message);
        })
    }, [setState, stopId, navigate, parents])

    if (error !== undefined) {
        return (<Message type="error" message={error} />);
    }
    if (isLoaded === false) {
        return (<Message type="loading" />);
    }
    else if (state === undefined || state.documents === undefined) {
        return (<Message type="noData" />);
    }

    const content = () => {
        return (
            <div className="component">
                <div className="outline-box text-component" style={{ width: "100%" }}>
                    <div className="buttons">
                        {Object.keys(state.documents).map((value, id) =>
                            state.documents[value].isSigned === false &&
                            <Link to={"/" + parents + "/sign/" + stopId} state={{ documentType: value }}  className={"button button--outline button--icon button--icon--transport button--readmore"}>
                                <span>{state.documents[value].typeDisplay}</span>
                            </Link>)
                        }
                    </div>
                </div>
            </div>
        );
    }


    // outline-box
    return (
        <ContentWrapper content={content()} type="page-component" />
        // <React.Fragment>
        //     <div className="main">
        //         <div className="main__left" />
        //         <div className="main__right">
        //             <div className="components">
        //                 <div className="container">
        //                     {/* <div className="row justify-content-center"> */}
        //                     <div className="col-12 col-md-8 col-lg-6">
        //                         <div className="component">
        //                             <div className="outline-box text-component" style={{ width: "100%" }}>
        //                                 <div className="buttons">
        //                                     {Object.keys(state.documents).map((value, id) =>
        //                                         state.documents[value].isSigned === false &&
        //                                         <Link to={"../sign/" + stopId} state={{ documentType: value }} style={{ width: "100%" }} className={"button button--outline button--icon button--icon--transport button--readmore"}>
        //                                             <span>{state.documents[value].typeDisplay}</span>
        //                                         </Link>)
        //                                     }
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //             {/* </div> */}
        //         </div>
        //     </div>
        // </React.Fragment>
    );
}