import axios from 'axios';
import { API_URL, getApiHeaders } from "./api.js"

const fetchInspections = formData => axios.post(
    `${API_URL}/admin/inspections/overview`, formData, getApiHeaders()
);

const processInspection = formData => axios.post(
    `${API_URL}/admin/inspections/process`, formData, getApiHeaders()
);

const getFormFieldsInspection = formData => axios.get(
    `${API_URL}/admin/inspections/getformdata/`+ formData, getApiHeaders()
);

const fetchInspectionTypes = () => axios.get(
    `${API_URL}/admin/inspections/types/custom`, getApiHeaders()
);


const fetchAllowedInspectionTypes = formData => axios.get(
    `${API_URL}/admin/inspections/allowed/`+ formData, getApiHeaders()
);

const processCreateInspection = formData =>  axios.post(
    `${API_URL}/admin/inspections/create`, formData, getApiHeaders()
)

export {
    fetchInspections,
    processInspection,
    getFormFieldsInspection,
    fetchInspectionTypes,
    fetchAllowedInspectionTypes,
    processCreateInspection
}


